var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.type === "Info"
        ? _c("class-info", {
            attrs: { classInfo: _vm.classInfo },
            on: { updateView: _vm.updateView },
          })
        : _vm.type === "Student"
        ? _c("class-student", {
            attrs: { classInfo: _vm.classInfo, classStudent: _vm.classStudent },
          })
        : _vm.type === "ClassStudentState"
        ? _c("class-student-state", { attrs: { classInfo: _vm.classInfo } })
        : _vm.type === "Catalogue"
        ? _c("class-catalogue", { attrs: { classInfo: _vm.classInfo } })
        : _vm.type === "Lesson"
        ? _c("class-lesson", { attrs: { classInfo: _vm.classInfo } })
        : _vm.type === "Relevancy"
        ? _c("class-relevancy", {
            attrs: {
              classInfo: _vm.classInfo,
              scrollTopTable: _vm.scrollTopTable,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }