<template>
  <div>
    <el-card shadow="never">
      <div slot="header" class="flex" style="align-items: center;">
        <span>排课数据</span>
        <el-popover
          placement="top-start"
          width="200"
          style="margin-left: 10px;"
          trigger="hover">
          <el-row class="condition-con">
            <el-row class="condition-flex"><span class="condition-span teacher"></span><span>讲师</span></el-row>
            <el-row class="condition-flex"><span class="condition-span assistant"></span><span>助教</span></el-row>
          </el-row>
          <span class="el-icon-question pointer" slot="reference"></span>
        </el-popover>
        <div class="flex_1"></div>
        <el-button type="primary" size="mini" @click="add()" v-has="'class:addLesson'" :disabled="Boolean(classInfo.finished_date) && (classInfo.finished_date !== '0001-01-01') && (classInfo.finished_date !== '1900-01-01')"
          >新增排课</el-button
        >
        <el-button plain @click="batchAdd" size="mini" v-has="'class:addLesson'"  :disabled="Boolean(classInfo.finished_date) && (classInfo.finished_date !== '0001-01-01') && (classInfo.finished_date !== '1900-01-01')">批量新增</el-button>

        <el-button plain @click="classEliminate" size="mini" v-has="'class:disappearLesson'" v-if="$route.query.productId" :disabled="Boolean(classInfo.finished_date) && (classInfo.finished_date !== '0001-01-01') && (classInfo.finished_date !== '1900-01-01')">消课数量管理</el-button>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>

      <el-table-bar fixed :static="true">
        <el-table
          :key="tableKey"
          :data="dataList"
          fit
          :highlight-current-row="true"
          @sort-change="tableSortAll"
          :default-sort="{ prop: 'start_time', order: 'ascending' }"
        >
        <!-- style="margin-top: 15px;" -->
          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <template v-if="item.label === '上课时间'">
                <span class="column-link" @click="particulars(scope.row)">
                  <span>（{{scope.row.start_time.substring(0, 10) | weekDay}}）</span>
                  {{ scope.row.start_time | formatDateStart }} {{scope.row.end_time | formatDateEnd }}</span>
              </template>
              <template v-else-if="item.label === '课堂任务'">
                <el-row v-if="scope.row.courseUnitlist.length != 0" :key="index" v-for="(item,index) in scope.row.courseUnitlist">
                  <span class="column-link" @click="manageLessTask(scope.row)">{{item.title}}</span>
                </el-row>
                <el-row v-if="scope.row.courseUnitlist.length == 0">
                  <span class="column-link" @click="manageLessTask(scope.row)">去选择</span>
                </el-row>
              </template>
              <template v-else-if="item.label === '学生'">
                <span
                  class="column-link"
                  @click="checkStu(scope.row)"
                >{{ scope.row.studentNum }}人</span>
              </template>
              <template v-else-if="item.label === '上课老师'">
                <el-row v-if="scope.row.zjName" class="mb-10">
                  <span class="zjName" v-for="(item, key) in scope.row.zjName.split(',')" :key="key + 'zjName'">
                    {{item}}
                  </span>
                </el-row>
                <el-row v-if="scope.row.assistant">
                  <span class="assistant" v-for="(item, key) in scope.row.assistant.split(',')" :key="key + 'assistant'">
                    {{item}}
                  </span>
                </el-row>
              </template>
              <template v-else-if="item.label === '课堂反馈'">
                 <span
                  class="span-state"
                  :style="
                    'backgroundColor' + ':' + getLessonFinishState(scope.row.hasFeedback)
                  "
                ></span>
                <span v-text="scope.row.hasFeedback"></span>
              </template>
              <template v-else-if="item.label === '上课教室'">
                <span v-if="scope.row.classroomTitle">{{scope.row.classroomTitle}}</span>
                <span v-else>--</span>
              </template>
              <template v-else-if="item.label === '排课状态'">
                <span
                  class="span-state"
                  :style="
                    'backgroundColor' + ':' + getStateColor(scope.row.finished_status)
                  "
                ></span>
                <span v-text="scope.row.finished_status"></span>
              </template>
              <template v-else-if="item.label === '排课操作'">
                <el-button type="text" @click="toClass(scope.row)"
                  >去上课</el-button
                >
                <el-divider direction="vertical"></el-divider>
                <el-dropdown trigger="click">
                  <span class="el-dropdown-link">
                    更 多<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="SyncClassNormal(scope.row)">同步本节课数据</el-dropdown-item>
                    <el-dropdown-item @click.native="editDeedBack(scope.row)" v-has="'lesson:feedback'">编辑课堂反馈</el-dropdown-item>
                    <el-dropdown-item @click.native="rollCall(scope.row)" v-has="'class:callStudent'" v-if="scope.row.studentNum !== 0 && classInfo.status === '已启用'">点 名</el-dropdown-item>
                    <el-dropdown-item
                      @click.native="editLess(scope.row)"
                      v-has="'class:editLessonInfo'"
                      v-if="!(scope.row.finished_status === '已上课')"
                    >
                      编辑排课
                    </el-dropdown-item>
                    <el-dropdown-item @click.native="applyCourse(scope.row)" >补课申请</el-dropdown-item>
                    <el-dropdown-item @click.native="delLesson(scope.row)" v-has="'class:delLesson'" v-if="!(scope.row.finished_status === '已上课')">删除排课</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
              <template v-else-if="item.label === '直播间操作'">
                <template v-if="Boolean(scope.row.liveId)">
                  <el-button type="text" @click="jumpLive(scope.row)"
                    >去直播</el-button
                  >
                  <el-divider direction="vertical"></el-divider>
                  <el-button type="text" @click="codeManage(scope.row)"
                    >参加码</el-button
                  ></el-button>
                  <el-divider direction="vertical"></el-divider>
                  <el-dropdown trigger="click">
                    <span class="el-dropdown-link">
                      更 多<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item @click.native="addLive(scope.row)">重建直播间</el-dropdown-item>
                      <el-dropdown-item @click.native="editLive(scope.row)">编辑直播间</el-dropdown-item>
                      <el-dropdown-item @click.native="delLive(scope.row)">删除直播间</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
                <template v-else>
                   <el-button type="text" @click="addLive(scope.row)" :disabled="Boolean(classInfo.finished_date) && (classInfo.finished_date !== '0001-01-01') && (classInfo.finished_date !== '1900-01-01')"
                    >创建直播间</el-button>
                </template>
              </template>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>

          <!-- <el-table-column>
            <template slot="header" slot-scope="scope">
              <el-popover
              placement="top-start"
              title="标题"
              width="200"
              trigger="hover"
              content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
              <el-button slot="reference">hover 激活</el-button>
            </el-popover>
            </template>
          </el-table-column> -->
        </el-table>
      </el-table-bar>

      <el-pagination
        class="fr-pagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>

    </el-card>

    <!-- 查看学员dialog -->
    <check-stu :lessonData="lessonData" :show="dialogStu" @close="dialogStu=false" :product_id="product_id" @updateView="getLessonInfoByClassId" :classInfo="classInfo"></check-stu>

    <!-- 管理课堂任务 -->
    <manage-lesson :show="lessonDialog" @close="lessonDialog=false;courseUnitIdList=[]" :lessonId="lesson_id" :classId="classId" @updateView="getLessonInfoByClassId" :courseUnitIdList="courseUnitIdList"></manage-lesson>

    <!-- 排课详情 -->
    <lesson-details :show="particularsDialog" @close="particularsDialog=false;lessonData={}" :lessonData="lessonData" type="class"></lesson-details>

    <!-- 新增排课 -->
    <add-lesson
      :addlessonData="addlessonData"
      :show="dialogLesson"
      :campusId="classInfo.campus_id"
      :classInfo="classInfo"
      @addLessonForm="addLessonForm"
      @close="close"
      @editLessonForm="editLessonForm"
      @getClassMessage="getLessonInfoByClassId"
    ></add-lesson>

    <!-- 批量增加排课 -->
    <batch-add :show="batchDialog" @close="batchDialog=false" @getClassMessage="getLessonInfoByClassId" :classInfo="classInfo"></batch-add>

    <!-- 消课数量管理 -->
    <eliminate-class :show="eliminateDialog" @close="eliminateDialog=false"></eliminate-class>

    <!-- 课堂反馈 -->
    <deed-back :show="DeedBackDialog" @close="DeedBackDialog=false" :lesson_id="lesson_id" @updateView="getLessonInfoByClassId()"></deed-back>

    <!-- 补课学员列表dialog -->
    <applay-course :applyCourseData="applyCourseData" :show="applyCourseFlag" @close="applyCourseFlag=false"></applay-course>

    <!-- 点名 -->
    <lesson-attendance :show="attendanceDialog" @close="attendanceDialog=false" :productId="product_id" :lesson_id="lesson_id" @updateView="getLessonInfoByClassId" :className="classInfo.className" :schooltime="schooltime"></lesson-attendance>

    <!-- 冲突检测 -->
    <detection-clash :show="clashDialog" @close="clashDialog=false;clashData=[]" :clashData="clashData"></detection-clash>

    <!-- 进入直播间 -->
    <join-live
      :show="dialogJionLive"
      @close="dialogJionLive=false"
      :liveRoomId="liveRoomId"
    ></join-live>
    <!-- 参加码管理 -->
    <code-manage
      :show="dialogCode"
      :liveRoomId="liveRoomId"
      @close="dialogCode=false"
    ></code-manage>
    <!-- 新建直播间 -->
    <add-live
     :show="dialogLive"
     :liveName="lessonLiveName"
     :liveData="liveData"
     :lessonData="lessonLiveData"
     :isLessonReCreate = true
      @close="dialogLive=false,liveData=null,lessonLiveData=null"
      @addLiveSave="addLiveSave"
      @editLiveSave="editLiveSave"
    ></add-live>
  </div>
</template>

<script>
import TextButton from '@/components/TextButton'
import SearchQuery from '@/components/SearchQuery'
import checkStu from '../check-student/index'
import manageLesson from '@/components/senate/manage-lesson/index'
import addLesson from '../add-lesson/index'
import batchAdd from '../batch-add/index'
import getStateColor from '@/mixins/getStateColor'
import { getFeedback,lessonAdd, lessonEdit } from '@/api/senate/lesson'
import eliminateClass from '@/components/senate/eliminate-class'
import DeedBack from '@/components/DeedBack'
import applayCourse from '../applay-course/index'
import detectionClash from '@/components/senate/detection-clash'
import lessonAttendance from './components/lesson-attendance'
import joinLive from './components/join-live'
import codeManage from './components/code-manage'
import addLive from '../add-live'
import { listCourseUnit, mapCourseUnits, getClassStudentInfoById, getClassTeacherInfoById, getLessonInfoByClassId, delLesson, SyncClassNormal, insertClassBliveRoom, updateClassBliveRoomById, deleteClassBliveRoomById } from '@/api/senate/class'
import LessonDetails from '@/components/senate/manage-lesson/LessonDetails'
import { formatDate } from '@/utils/formatDate'

// 默认展示列
const baseColSetting = [
  {
    label: '上课时间',
    prop: 'start_time',
    state: true,
    sortable: true,
    align: 'center',
    width: '255',
  },
  {
    label: '课堂任务',
    prop: 'courseUnitlist',
    state: true,
    align: 'center',
    width: '150',
  },
  {
    label: '学生',
    prop: 'studentNum',
    state: true,
    align: 'center',
    width: '70',
  },
  {
    label: '上课老师',
    prop: '',
    state: true,
    align: 'center',
    width: '150',
  },
  {
    label: '上课教室',
    prop: 'classroomTitle',
    state: false,
    align: 'center',
    width: '100',
  },
  {
    label: '排课状态',
    prop: 'finished_status',
    state: true,
    align: 'center',
    width: '100',
  },
  {
    label: '课堂反馈',
    prop: 'hasFeedback',
    state: false,
    align: 'center',
    width: '100',
  },
  {
    label: '来源',
    prop: 'source',
    state: false,
    align: 'center',
    width: '100',
  },
  {
    label: '排课操作',
    state: true,
    align: 'center',
    width: '150',
  },
  {
    label: '直播间操作',
    state: true,
    align: 'center',
    width: '200',
  },
]
export default {
  props: {
    classInfo: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  mixins: [getStateColor],
  data() {
    return {
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
      },
      dataList: [],
      tableKey: 1,
      isDesc: false, // 列表默认为正序
      dialogStu: false,
      lessonData: {},
      product_id: this.$route.query.productId, // 收费课程id
      lessonDialog: false,
      courseUnitIdList: [],
      lesson_id: '',
      classId: '',
      particularsDialog: false,
      dialogLesson: false,
      addlessonData: null,
      batchDialog: false,
      eliminateDialog: false,
      DeedBackDialog: false,
      attendanceDialog: false,
      schooltime: '', // 上课时间
      applyCourseFlag: false,
      applyCourseData: {},
      stuList: [], // 学员列表
      dialogJionLive: false,
      liveRoomId: '',
      dialogCode: false,
      dialogLive: false,
      lessonLiveName: '',
      liveData: null,
      lessonLiveData: null,
      clashDialog:false,
      clashData:[],
    }
  },
  created () {
    this.getLessonInfoByClassId()
    this.getClassStudentInfoById()
    // this.getClassTeacherInfoById()
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter(item => item.state)
    },
  },
  methods: {
    async getLessonInfoByClassId () { // 获取班级排课列表
      const res = await getLessonInfoByClassId({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        class_id: this.$route.query.classID,
        isDesc: this.isDesc
      })
      const dataList = res.body.list
      dataList.forEach((ele, idx) => {
        ele.hasFeedback = null
        this.getFeedback(ele.id, idx)
      })

      this.dataList = dataList
      this.pagination.total = res.body.total
    },

    jumpLive (row) {
      this.liveRoomId = row.liveId
      this.dialogJionLive = true
    },

    async getClassStudentInfoById () {
      const res = await getClassStudentInfoById({
        class_id: this.$route.query.classID
      })
      if (res.state === 'success') {
        this.stuList = res.body
      }
    },

    codeManage (row) {
      this.liveRoomId = row.liveId
      this.dialogCode = true
    },

    // 去上课
    toClass (row) {
      if (row.courseUnitlist.length === 0) {
        window.$msg('您还未选择课堂任务，请先选择课堂任务', 2)
      } else {
        this.$router.push({
          path: '/senate/classroom-task',
          query: {
            lessonObject:JSON.stringify(row),
            classId: this.$route.query.classID,
            lessonId: row.id
          }
        })
      }
    },

    async addLiveSave (data) {
      await insertClassBliveRoom({
        title: data.title,
        start_time: data.startTime,
        end_time: data.endTime,
        type: data.type,
        max_users: data.type === 4 ? data.max_users : null,
        max_backup_users: data.type === 4 ? data.max_backup_users : null,
        class_id: this.$route.query.classID,
        lesson_id: data.lesson_id,
        source: data.source,
      })
      window.$msg('新增成功')
      this.getLessonInfoByClassId()
    },

    async editLiveSave(data) {
      await updateClassBliveRoomById({
        id: data.id,
        title: data.title,
        start_time: data.startTime,
        end_time: data.endTime,
        type: data.type,
        max_users: data.type === 4 ? data.max_users : null,
        max_backup_users: data.type === 4 ? data.max_backup_users : null,
        class_id: this.$route.query.classID,
        lesson_id: data.lesson_id,
        source: data.source,
      })
      window.$msg('编辑成功')
      this.getLessonInfoByClassId()
    },

    addLive (row) {
      this.lessonLiveName = `${this.classInfo.className} 第${row.num}节`
      this.lessonLiveData = row
      this.dialogLive = true
    },

    // 编辑直播间
    editLive(row) {
      this.liveData = row
      this.dialogLive = true
    },

    // 删除直播间
    delLive(row) {
      console.log('row', row)
      this.$confirm('是否删当前排课直播间', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await deleteClassBliveRoomById({
            liveRoomId: row.liveId
          })
          window.$msg('删除成功')
          this.getLessonInfoByClassId()
        })
    },

    // 编辑课堂反馈
    editDeedBack(row) {
      this.lesson_id = row.id
      this.DeedBackDialog = true
    },

    update(data) {
      this.tableKey = this.tableKey + 1
      if (data.lenght !== 0) {
        this.colSetting.forEach(item => {
          item.state = false
          data.forEach(ele => {
            if (ele === item.label && !item.state) {
              item.state = true
            }
          })
        })
      } else {
        this.colSetting = []
      }
    },

    async getFeedback(id, idx) {
      const res = await getFeedback({
        lessonId: id
      })
      this.dataList[idx].hasFeedback = Boolean(res.body.feedback) ? '已填写' : '未填写'
    },

    add () {
      this.dialogLesson = true
    },

    // 同步本节课数据
    async SyncClassNormal (row) {
      const res = await SyncClassNormal({
        lessonId: row.id
      })
      if (res.state === 'success') {
        this.getLessonInfoByClassId()
        window.$msg('操作成功')
      } else {
        window.$msg(res.errMsg, 2)
      }
    },

    // 批量排课
    batchAdd () {
      this.batchDialog = true
    },

    // 消课数量管理
    classEliminate () {
      this.eliminateDialog = true
    },

    // 点名
    rollCall (row) {
      if (row.courseUnitlist.length === 0) {
        window.$msg('您还未选择课堂任务，请先选择课堂任务', 2)
      } else {
        const startTime = row.start_time + '~'
        const endTime = row.end_time.substring(11)
        this.schooltime = startTime + endTime
        this.lesson_id = row.id
        this.attendanceDialog = true
      }
    },

    // 新增排课
    addLessonForm (data) {
      let addLesson = {
        class_id: this.$route.query.classID, // 班级id
        start_time: data.start_time, //  上课开始时间
        end_time: data.endTime,//  上课结束时间
        teacherIds: data.teacherIds, //  上课老师
        studentIds: data.studentIds, //  上课学生
        assistantIds: data.assistantIds, //  助教老师
        contestIds: data.contestIds, //  题库id
        classroom_id: data.roomIds.lenght !== 0 ? data.roomIds[0] : null,
        courseUnitIds: [] //  课节id
      }
      lessonAdd({ ...addLesson }).then(res => {
        if (res.state === 'success') {
          window.$msg('新增成功')
          this.close()
          this.getLessonInfoByClassId()
        } else {
          if (res.errCode === '0201001') {
            this.clashFun(res)
          }
        }
      })
    },

    // 编辑排课
    async editLessonForm (data) {
      let start_time = data.start_time
      let hour = data.hour
      let minute = data.minute
      let b = new Date(Number(new Date(start_time).getTime()) + (Number(hour) * 3600 * 1000) + (Number(minute) * 60 * 1000))
      let end_time = formatDate(b, 'yyyy-MM-dd hh:mm:ss')
      let classroom_id = null
      if (data.roomIds && data.roomIds.length !== 0) {
        classroom_id = data.roomIds[0]
      } else {
        if (!data.classroom_id) {
          classroom_id = null
        } else {
          classroom_id = data.classroom_id
        }
      }
      let editLesson = {
        id: data.id,
        course_unit_id: '',
        start_time: data.start_time,
        end_time: data.endTime,
        classroom_id: classroom_id
      }
      const res = await lessonEdit({ ...editLesson })
      if (res.state === 'success') {
        window.$msg('编辑成功')
        this.getLessonInfoByClassId()
        this.close()
      } else {
        if (res.errCode === '0201001') {
          this.clashFun(res)
        }
      }
      // this.mapCourseUnits(data.id,data.courseUnitId)
      // this.getCourseUnitList();
    },

    clashFun (res) {
      if (res.body && res.body.length > 0) {
        var newArr = [];
        res.body.forEach((item, index) => {
          var flag = true
          if (newArr.length > 0) {
            for (var i= 0; i<newArr.length; i++) {
              if (newArr[i].id === item.id && newArr[i].conflictType !== item.conflictType) {
                console.log('相同id的冲突')
                newArr[i].conflictType = [newArr[i].conflictType, item.conflictType],
                  newArr[i].classroomId = newArr[i].classroomId ? newArr[i].classroomId : item.classroomId,
                  newArr[i].classroomTitle = newArr[i].classroomTitle ? newArr[i].classroomTitle : item.classroomTitle,
                  newArr[i].teacherId = newArr[i].teacherId ? newArr[i].teacherId : item.teacherId,
                  newArr[i].teacherName = newArr[i].teacherName ? newArr[i].teacherName : item.teacherName,
                  newArr[i].teacherType = newArr[i].teacherType ? newArr[i].teacherType : item.teacherType,
                  flag = false
                break
              }
            }
          }
          if (flag) {
            newArr.push(item)
          }
        })
      }
      this.clashData = newArr
      this.clashDialog = true
    },

    particulars(row) {
      this.lessonData = row
      this.particularsDialog = true
    },

    close () {
      this.dialogLesson = false
      this.addlessonData = null
    },

    // 管理课堂任务
    manageLessTask (row) {
      if (row.finished_status === '已上课') {
        window.$msg('该排课已上课', 2)
        return false
      }
      if (row.courseUnitlist.length !== 0) {
        const courseUnitIdList = []
        row.courseUnitlist.forEach((ele, idx) => {
          courseUnitIdList.push(ele.id)
        })
        this.courseUnitIdList = courseUnitIdList
      }
      this.lesson_id = row.id
      this.classId = this.$route.query.classID
      this.lessonDialog = true
    },

    search() {
      this.pagination.currentPage = 1
      this.getLessonInfoByClassId()
    },

    // 时间排序
    tableSortAll(column) {
      switch (column.order) {
        case 'ascending':
          this.isDesc = false
          break
        case 'descending':
          this.isDesc = true
      }
      this.getLessonInfoByClassId()
    },

    // 分页
    handleCurrentChange(val) {
      this.pagination.currentPage = val
      this.getLessonInfoByClassId()
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val
      this.getLessonInfoByClassId()
    },

    applyCourse (row) {
      if (row.courseUnitlist.length === 0) {
        window.$msg('请先关联课节后再申请补课', 2)
        return false
      }
      this.applyCourseData = row
      this.applyCourseFlag = true
    },

    getStateColor (state) {
      switch (state) {
        case '已上课':
          return '#4DA54D'
        case '未上课':
          return '#f56c6c'
        default:
          return '#C8C6C6'
      }
    },

    // 编辑排课
    editLess (row) {
      let studentIds = []
      if (this.stuList.length !== 0) {
        this.stuList.map(item => {
          studentIds.push(item.user_id)
        })
      }
      this.class_id = row.class_id
      this.listCourseUnit(row)
    },

    async listCourseUnit (row) {
      const res = await listCourseUnit({
        lessonId: row.id
      })
      if (res.state === 'success') {
        var courseUnitId = []
        for (let i = 0; i < res.body.length; i++) {
          courseUnitId.push(res.body[i].id)
        }
        this.addlessonData = row
        this.addlessonData.courseUnitId = courseUnitId

        this.dialogLesson = true
      }
    },

    // 删除排课
    delLesson (row) {
      this.$confirm('确定删除当前排课吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delectLesson(row)
        })
        .catch(() => {
          return false
        })
    },

    async delectLesson (row) {
      const res = await delLesson({
        ids: [row.id]
      })
      if (res.state === 'success') {
        window.$msg('删除成功')
        this.getLessonInfoByClassId()
      } else {
        window.$msg(res.errMsg, 2)
      }
    },

    checkStu (row) {
      this.lessonData = row
      this.lessonData.class_id = this.$route.query.classID
      this.dialogStu = true
    },
  },
  components: {detectionClash,TextButton, SearchQuery, checkStu, manageLesson, LessonDetails, addLesson, batchAdd, eliminateClass, DeedBack, applayCourse, lessonAttendance, joinLive, codeManage, addLive}
}
</script>

<style lang="scss" scoped>
@import '../../../style/container.scss';
</style>
