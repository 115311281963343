<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    append-to-body
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>批量排课</span>
    </div>
    <el-form ref="formData" :model="formData" :rules="rules" label-width="120px">
      <el-form-item label="上课老师：" prop="teacherIds">
        <el-row>
          <el-col :span="14">
            <div style="margin-right: 20px">
              <el-select
                v-model="formData.teacherIds"
                multiple
                placeholder="请选择上课老师"
                @click.native="seleTeacher"
                style="width: 100%"
              >
                <el-option
                  v-for="item in teacherIds"
                  :key="item.userId"
                  :label="item.name"
                  :value="item.userId"
                ></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="8">
            <div><el-button type="primary" size="small" @click="checkTime" v-has="'class:roomFreeTime'">查看老师空闲时间</el-button></div>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="排课教室：" prop="roomIds">
        <el-row>
          <el-col :span="14">
            <div style="margin-right: 20px">
              <el-select
                v-model="formData.roomIds"
                multiple
                placeholder="请选择教室"
                @click.native="seleRoom"
                style="width: 100%;cursor: pointer"
              >
                <el-option
                  v-for="item in roomIds"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="8">
            <div><el-button type="primary" size="small" @click="checkRoomTime" v-has="'class:roomFreeTime'">查看教室空闲时间</el-button></div>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="上课助教：">
        <el-select
          v-model="formData.assistantIds"
          multiple
          placeholder="请选择助教"
          @click.native="seleAssistant"
          style="width: 100%"
        >
          <el-option
            v-for="item in assistantIds"
            :key="item.userId"
            :label="item.name"
            :value="item.userId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开始日期：" prop="beginDate">
        <el-row>
          <el-date-picker
            v-model="formData.beginDate"
            style="float: left"
            type="datetime"
            format="yyyy-MM-dd HH:mm"
            @change="startTimeChange"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择开始上课时间">
          ></el-date-picker>
          <el-row style="float: left" v-if="formData.beginDate">
            <el-dropdown trigger="click" style="margin-left: 10px;"  v-if="commonTimeOptions.length !== 0" v-model="commonTime"  @command="commonTimeChange">
            <span class="el-dropdown-link">
              常用时间段<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="item"   v-for="item in commonTimeOptions">
                  <span style="float: left">{{ item.startTime }}~</span>
                  <span style="float: left">{{ item.endTime }}</span>
                  <span class="el-dropdown-link" style="float: right;margin-left: 15px" @click.stop="deleteCommonTime(item)">删除</span>
                </el-dropdown-item>
                <el-button style="margin: 10px;" size="mini" type="primary" @click="gotoAddCommenTime">添加常用时间段</el-button>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button v-else style="margin-left: 10px;" size="mini" type="primary" @click="gotoAddCommenTime">添加常用时间段</el-button>
<!--            <el-select style="margin-left: 10px;" v-if="commonTimeOptions.length !== 0" v-model="commonTime"  @change="commonTimeChange" placeholder="常用时间段">-->
<!--              <el-option-->
<!--                v-for="item in commonTimeOptions"-->
<!--                :key="item.label"-->
<!--                :label="item.label"-->
<!--                :value="item.label"-->
<!--              >-->
<!--                <span style="float: left">{{ item.startTime }}~</span>-->
<!--                <span style="float: left">{{ item.endTime }}</span>-->
<!--                <span class="el-dropdown-link" style="float: right" @click.stop="deleteCommonTime(item)">删除</span>-->
<!--              </el-option>-->
<!--              <el-button style="margin: 10px;" size="mini" type="primary" @click="gotoAddCommenTime">添加常用时间段</el-button>-->
<!--            </el-select>-->
<!--            <el-button v-else style="margin-left: 10px;" size="mini" type="primary" @click="gotoAddCommenTime">添加常用时间段</el-button>-->
          </el-row>
        </el-row>
        <el-row>
          <!-- formData.weekSet： {{formData.weekSet}} -->
          <el-checkbox-group v-model="formData.weekSet">
            <el-checkbox :label="2">周一</el-checkbox>
            <el-checkbox :label="3">周二</el-checkbox>
            <el-checkbox :label="4">周三</el-checkbox>
            <el-checkbox :label="5">周四</el-checkbox>
            <el-checkbox :label="6">周五</el-checkbox>
            <el-checkbox :label="7">周六</el-checkbox>
            <el-checkbox :label="1">周日</el-checkbox>
          </el-checkbox-group>
        </el-row>
      </el-form-item>
      <el-form-item label="上课时长：" prop="lessonLong">
        <el-input-number style="width: 20%;" :precision="0" v-model="formData.lessonLong" :min="0" :controls="false" @change="timeDurationhandleChange"></el-input-number>
        <span style="margin-left: 10px;margin-right: 30px;">分钟</span>
        <el-dropdown trigger="click" style="margin-left: 10px;"   v-model="timeDurationSelect"  @command="timeDurationSelectChange">
            <span class="el-dropdown-link">
              常用时长<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="item.value"   v-for="item in timeDurationOptions">
              <span style="float: left">{{ item.label}}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
<!--        <span style="margin-right: 10px;">常用时长</span>-->
<!--        <el-select v-model="timeDurationSelect" placeholder="快捷选择时长" @change="timeDurationSelectChange" clearable>-->
<!--          <el-option-->
<!--            v-for="item in timeDurationOptions"-->
<!--            :key="item.value"-->
<!--            :label="item.label"-->
<!--            :value="item.value">-->
<!--          </el-option>-->
<!--        </el-select>-->
      </el-form-item>
      <el-form-item label="排课节数：" prop="lessonTimes">
        <el-input-number v-model="formData.lessonTimes" :min="1"></el-input-number>
      </el-form-item>
    </el-form>

    <el-row slot="footer">
      <el-button type="primary" @click="confirm" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>

    <!-- 查看老师空闲时间 -->
    <check-time :show="timeDialog" @close="timeDialog=false"></check-time>
    <!-- 选择老师 -->
    <sele-teacher :show="teacherDialog" @close="teacherDialog=false" @teacherData="teacherData" :seleTeaData="seleTeaData"></sele-teacher>
    <!-- 选择助教 -->
    <sele-assistant :show="assistantDialog" @close="assistantDialog=false" @assistantData="assistantData" :seleAssisData="seleAssisData"></sele-assistant>
    <!-- 冲突检测 -->
    <detection-clash :show="clashDialog" @close="clashDialog=false;clashData=[]" :clashData="clashData"></detection-clash>
    <!-- 选择教室 dialog -->
    <sele-room :show="roomDialog" :campusId="classInfo.campus_id" @close="roomDialog=false" @seleRoomData="seleRoomData" :lessRoom="lessRoom"></sele-room>
    <!-- 查看教室空闲时间 -->
    <room-time :show="roomTimeDialog" @close="roomTimeDialog=false"></room-time>
    <add-common-time :show="showCommomTime" @close="closeCommonTimeDialog" ></add-common-time>
  </el-dialog>
</template>

<script>
import { addBat } from '@/api/senate/class'
import CheckTime from '@/components/senate/check-time'
import seleTeacher from '@/components/senate/sele-newteacher'
import seleAssistant from '@/components/senate/sele-newassistantIds'
import detectionClash from '@/components/senate/detection-clash'
import seleRoom from '@/components/senate/sele-room'
import roomTime from '@/components/senate/room-time'
import {listTeacherLevel,deleteUserPeriod} from '@/api/user'
import addCommonTime from '@/components/senate/add-commontime'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    classInfo: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  components: {
    CheckTime,
    seleTeacher,
    seleAssistant,
    detectionClash,
    seleRoom,
    roomTime,
    addCommonTime
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  data () {
    return {
      commonTimeOptions:[],
      showCommomTime:false,
      commonTime:'',
      formData: {
        hour: '00',
        minute: '00',
        lessonTimes: 1,
        weekSet: [],
        teacherIds: [],
        assistantIds: [],
        roomIds: [],
      },
      timeDialog: false,
      rules: {
        teacherIds: [
          { required: true, message: '上课讲师不能为空', trigger: 'blur' }
        ],
        roomIds: [
          { required: true, message: '排课教室不能为空', trigger: 'blur' }
        ],
        assistantIds: [
          { required: true, message: '上课助教不能为空', trigger: 'blur' }
        ],
        beginDate: [
          { required: true, message: '开始时间不能为空', trigger: 'blur' }
        ],
        lessonLong: [
          { required: true, message: '上课时间不能为空', trigger: 'blur' }
        ],
        lessonTimes: [
          { required: true, message: '排课节数不能为空', trigger: 'blur' }
        ]
      },
      teacherIds: [], // 上课讲师
      assistantIds: [], // 上课助教
      roomIds: [], // 排课教室
      teacherDialog: false,
      assistantDialog: false,
      roomDialog: false,
      timeDialog: false,
      roomTimeDialog: false,
      seleTeaData: [],
      lessRoom: [],
      seleAssisData: [],
      clashDialog: false,
      clashData: [],
      timeDurationSelect:'',
      timeDurationOptions: [ // 时长
        {
          value: 30,
          label: '30分钟'
        },
        {
          value: 45,
          label: '45分钟'
        },
        {
          value: 60,
          label: '60分钟'
        },
        {
          value: 90,
          label: '90分钟'
        },
        {
          value: 120,
          label: '120分钟'
        },
        {
          value: 180,
          label: '180分钟'
        }
      ],
    }
  },
  methods: {
    startTimeChange() {
      this.commonTime = ''
    },
    commonTimeChange(command) {
      if(this.formData.beginDate !== undefined){
        console.log(this.commonTime.split('~')[0])
        this.formData.beginDate = this.formData.beginDate.substring(0,10)+' '+command.startTime+':00'
        this.formData.endTime = this.formData.beginDate.substring(0,10)+' '+command.endTime+':00'
        this.formData.lessonLong = ((new Date(this.formData.endTime)).getTime() - (new Date(this.formData.beginDate)).getTime()) / 60 / 1000

        let flag = false
        this.timeDurationOptions.forEach((item) => {
          if (item.value === parseInt(this.formData.lessonLong)) {
            this.timeDurationSelect = this.formData.lessonLong
            flag = true
          }
        })
        if(!flag) {
          this.timeDurationSelect = ''
        }
      }
    },
    async deleteUserPeriod(row) {
      const response = await deleteUserPeriod({
        usperiodId:row.id
      })
      if(response && response.state === 'success') {
        window.$msg('删除成功')
        this.listTeacherLevel()
        this.commonTime = ''
        this.$forceUpdate()
      }
    },
    async listTeacherLevel() {
      const response = await listTeacherLevel()
      if(response && response.state === 'success') {
        this.commonTimeOptions = response.body
      }
      this.commonTimeOptions.forEach((item)=>{
        item.label = item.startTime+'~'+item.endTime
      })
    },
    closeCommonTimeDialog() {
      this.showCommomTime = false
      this.commonTime = ''
      this.listTeacherLevel()
      this.$forceUpdate()
    },
    deleteCommonTime(row) {
      this.$confirm('确定删除此常用时间段吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteUserPeriod(row)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    gotoAddCommenTime() {
      this.showCommomTime = true
    },
    timeDurationhandleChange() {
      this.commonTime = ''
      let flag = false
      this.timeDurationOptions.forEach((item) => {
        if (item.value === parseInt(this.formData.lessonLong)) {
          this.timeDurationSelect = this.formData.lessonLong
          flag = true
        }
      })
      if(!flag) {
        this.timeDurationSelect = ''
      }
    },
    timeDurationSelectChange(command) {
      this.commonTime = ''
      this.timeDurationSelect = command
      this.formData.lessonLong = this.timeDurationSelect
    },
    checkRoomTime () {
      this.roomTimeDialog = true
    },

    seleRoomData (data) {
      this.lessRoom = [ ...data ]
      this.roomIds = data
      let roomIdsArray = []
      this.roomIds.map(item => {
        roomIdsArray.push(item.id)
      })
      this.formData.roomIds = roomIdsArray
    },

    openDialog () {
      this.formData = {
        hour: '00',
        minute: '00',
        lessonTimes: 1,
        weekSet: [],
        teacherIds: [],
        assistantIds: [],
        roomIds: []
      }
      this.listTeacherLevel()
      this.timeDurationSelect = ''
      this.lessRoom = []
      if (this.classInfo.classroomId) {
        this.lessRoom.push({
          id: this.classInfo.classroomId,
          title: this.classInfo.classroomTitle
        })
        this.roomIds = this.lessRoom
        let roomIdsArray = []
        this.roomIds.map(item => {
          roomIdsArray.push(item.id)
        })
        this.formData.roomIds = roomIdsArray
      }
      this.seleTeaData = []
      this.seleAssisData = []
    },

    close () {
      this.$emit('close')
    },

    // 选择讲师
    seleTeacher () {
      this.teacherIds.forEach((ele, idx) => {
        this.formData.teacherIds.forEach((item, index) => {
          if (ele.userId === item) {
            this.seleTeaData.push(ele)
          }
        })
      })
      this.teacherDialog = true
    },

    // 选择助教
    seleAssistant () {
      this.assistantIds.forEach((ele, idx) => {
        this.formData.assistantIds.forEach((item, index) => {
          if (ele.userId === item) {
            this.seleAssisData.push(ele)
          }
        })
      })
      this.assistantDialog = true
    },

    // 选择教室
    seleRoom () {
      this.roomDialog = true
    },

    forceUpdate () {
      this.$forceUpdate()
    },

    checkTime () {
      this.timeDialog = true
    },

    teacherData (data) {
      this.teacherIds = data
      let teacherIdsArray = []
      this.teacherIds.map(item => {
        teacherIdsArray.push(item.userId)
      })
      this.formData.teacherIds = teacherIdsArray
    },

    assistantData (data) {
      this.assistantIds = data
      let assistantArray = []
      this.assistantIds.map(item => {
        assistantArray.push(item.userId)
      })
      this.formData.assistantIds = assistantArray
    },

    changeStrToMinutes (str) {
      var arrminutes = str.split(':')
      if (arrminutes.length === 2) {
        var minutes = parseInt(arrminutes[0]) * 60 + parseInt(arrminutes[1])
        return minutes
      } else {
        return 0
      }
    },

    confirm () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          if(this.formData.lessonLong === 0) {
            window.$msg('上课时长不能为0',2)
            return false
          }
          if (this.formData.weekSet.length > Number(this.formData.lessonTimes)) {
            window.$msg('排课节数少于批量排课数量', 2)
          } else {
            let confirmData = {
              classId: this.$route.query.classID,
              teacherId: this.formData.teacherIds[0],
              assistantIdSet: this.formData.assistantIds,
              beginDate: this.formData.beginDate.substring(0, 10),
              startTimeSet: [this.formData.beginDate.substring(11, 19)],
              lessonLong: this.formData.lessonLong,
              lessonTimes: this.formData.lessonTimes,
              classroomId: this.formData.roomIds.length !== 0 ? this.formData.roomIds[0] : null,
              weekSet: this.formData.weekSet
            }
            addBat({
              ...confirmData
            }).then(res => {
              if (res.state === 'success') {
                this.close()
                this.$emit('getClassMessage')
              } else {
                if (res.errCode === '0201001') {
                  // console.log('排课教师冲突')
                  this.clashData = res.body
                  this.clashData.forEach((item)=> {
                    item.conflictType = item.conflictType + ""
                  })
                  this.clashDialog = true
                }
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
.duration {
  width: 35px;
  border: 0 !important;
  /deep/ .el-input__inner {
    width: 35px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    border-radius: 0;
    padding: 0 9px;
  }
}
</style>
