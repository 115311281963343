var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        { staticClass: "box-line my-lesson" },
        [
          _vm._v(" 课程目录 "),
          _c("el-row", { staticClass: "lesson-num" }, [
            _c(
              "span",
              [
                _vm._v(" 总课次： "),
                _c("font", [_vm._v(_vm._s(_vm.classNum))]),
              ],
              1
            ),
            _c(
              "span",
              [
                _vm._v(" 总任务数： "),
                _c("font", [_vm._v(_vm._s(_vm.taskNum))]),
              ],
              1
            ),
          ]),
          _vm._l(_vm.taskData, function (item, topindex) {
            return _c(
              "el-collapse",
              {
                key: topindex,
                attrs: { accordion: "" },
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.activeNames,
                  callback: function ($$v) {
                    _vm.activeNames = $$v
                  },
                  expression: "activeNames",
                },
              },
              [
                _c(
                  "el-collapse-item",
                  { attrs: { name: topindex + "" } },
                  [
                    _c(
                      "template",
                      { slot: "title" },
                      [
                        _vm._v(
                          " 第" +
                            _vm._s(
                              (_vm.pagination.currentPage - 1) * 10 +
                                Number(topindex + 1)
                            ) +
                            "课：" +
                            _vm._s(item.title) +
                            " "
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "20px" },
                            attrs: {
                              plain: "",
                              size: "small",
                              type: "primary",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.missedLessonManage(
                                  _vm.taskData[topindex]
                                )
                              },
                            },
                          },
                          [_vm._v("查询课件")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      {
                        staticStyle: { "padding-left": "20px" },
                        attrs: { reverse: _vm.reverse },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            ref: "taskList",
                            refInFor: true,
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.taskList,
                              fit: "",
                              "highlight-current-row": "",
                            },
                            on: { "selection-change": _vm.handleTaskSelection },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                type: "selection",
                                width: "55",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                width: "120",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              (_vm.pagination.currentPage - 1) *
                                                10 +
                                                Number(scope.$index + 1)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "任务标题",
                                width: "200",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "title-link",
                                            on: {
                                              click: function ($event) {
                                                return _vm.previewTask(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(scope.row.title))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "类型",
                                width: "120",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          { staticClass: "title-link" },
                                          [_vm._v(_vm._s(scope.row.type))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "操作学员",
                                width: "120",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              "margin-left": "10px",
                                              "margin-top": "5px",
                                            },
                                            attrs: {
                                              slot: "reference",
                                              plain: "",
                                              size: "small",
                                              type: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openStudent(
                                                  scope.row
                                                )
                                              },
                                            },
                                            slot: "reference",
                                          },
                                          [_vm._v("开放学员")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "操作",
                                width: "300",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.type === "测试"
                                          ? _c(
                                              "el-popover",
                                              {
                                                staticClass: "previewquestion",
                                                attrs: {
                                                  placement: "left",
                                                  trigger: "click",
                                                  width: "238",
                                                },
                                              },
                                              [
                                                _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "stuList clearfix",
                                                  },
                                                  _vm._l(
                                                    _vm.questionArray,
                                                    function (value, index) {
                                                      return _c(
                                                        "li",
                                                        { key: index },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "stupreview-tit",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "dateFormat"
                                                                  )(
                                                                    value.start_time
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "el-button",
                                                            {
                                                              staticClass:
                                                                "stu-switch",
                                                              attrs: {
                                                                size: "small",
                                                                type: "primary",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.openmarkingDialog(
                                                                      value.id,
                                                                      scope.row
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("查看")]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      slot: "reference",
                                                      plain: "",
                                                      size: "small",
                                                      type: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.queryQuestion(
                                                          scope.$index
                                                        )
                                                      },
                                                    },
                                                    slot: "reference",
                                                  },
                                                  [_vm._v("审阅试卷")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "flex-direction": "row",
                                              "justify-content": "center",
                                            },
                                          },
                                          [
                                            scope.row.type === "编程"
                                              ? _c(
                                                  "el-popover",
                                                  {
                                                    attrs: {
                                                      placement: "left",
                                                      trigger: "click",
                                                      width: "300",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "ul",
                                                      {
                                                        staticClass:
                                                          "stuList clearfix",
                                                      },
                                                      _vm._l(
                                                        _vm.questionArray,
                                                        function (
                                                          value,
                                                          index
                                                        ) {
                                                          return _c(
                                                            "li",
                                                            { key: index },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "stupreview-tit",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "dateFormat"
                                                                      )(
                                                                        value.start_time
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  staticClass:
                                                                    "stu-switch",
                                                                  attrs: {
                                                                    size: "small",
                                                                    type: "primary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        return _vm.queryItemTime(
                                                                          value,
                                                                          scope.$index
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "设置时间"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                        attrs: {
                                                          slot: "reference",
                                                          plain: "",
                                                          size: "small",
                                                          type: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.queryQuestion(
                                                              scope.$index
                                                            )
                                                          },
                                                        },
                                                        slot: "reference",
                                                      },
                                                      [_vm._v("设置查看时间")]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            scope.row.type === "编程"
                                              ? _c(
                                                  "el-popover",
                                                  {
                                                    attrs: {
                                                      placement: "left",
                                                      trigger: "click",
                                                      width: "238",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "ul",
                                                      {
                                                        staticClass:
                                                          "stuList clearfix",
                                                      },
                                                      _vm._l(
                                                        _vm.questionArray,
                                                        function (
                                                          value,
                                                          index
                                                        ) {
                                                          return _c(
                                                            "li",
                                                            { key: index },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "stupreview-tit",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "dateFormat"
                                                                      )(
                                                                        value.start_time
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  staticClass:
                                                                    "stu-switch",
                                                                  attrs: {
                                                                    size: "small",
                                                                    type: "primary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        return _vm.queryItem(
                                                                          value,
                                                                          scope.$index
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v("查看")]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                        attrs: {
                                                          slot: "reference",
                                                          plain: "",
                                                          size: "small",
                                                          type: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.queryQuestion(
                                                              scope.$index
                                                            )
                                                          },
                                                        },
                                                        slot: "reference",
                                                      },
                                                      [_vm._v("查看题库")]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        scope.row.type === "python"
                                          ? _c(
                                              "el-popover",
                                              {
                                                staticClass: "previewquestion",
                                                attrs: {
                                                  placement: "left",
                                                  trigger: "click",
                                                  width: "238",
                                                },
                                              },
                                              [
                                                _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "stuList clearfix",
                                                  },
                                                  _vm._l(
                                                    _vm.lessonList,
                                                    function (value, index) {
                                                      return _c(
                                                        "li",
                                                        { key: index },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "stupreview-tit",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "formatTime"
                                                                  )(
                                                                    value.start_time
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "el-button",
                                                            {
                                                              staticClass:
                                                                "stu-switch",
                                                              attrs: {
                                                                size: "small",
                                                                type: "primary",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.submitListManage(
                                                                      scope.row,
                                                                      value.id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("查看")]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      slot: "reference",
                                                      plain: "",
                                                      size: "small",
                                                      type: "primary",
                                                    },
                                                    slot: "reference",
                                                  },
                                                  [_vm._v("查看提交")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        scope.row.type === "上传"
                                          ? _c(
                                              "el-button",
                                              {
                                                staticClass: "previewquestion",
                                                attrs: {
                                                  slot: "reference",
                                                  plain: "",
                                                  size: "small",
                                                  type: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.priviewFile(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                                slot: "reference",
                                              },
                                              [_vm._v("查看作品")]
                                            )
                                          : _vm._e(),
                                        scope.row.type === "scratch"
                                          ? _c(
                                              "el-button",
                                              {
                                                staticClass: "previewquestion",
                                                attrs: {
                                                  slot: "reference",
                                                  plain: "",
                                                  size: "small",
                                                  type: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.priviewScratch(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                                slot: "reference",
                                              },
                                              [_vm._v("查看作品")]
                                            )
                                          : _vm._e(),
                                        scope.row.type === "goc"
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  slot: "reference",
                                                  plain: "",
                                                  size: "small",
                                                  type: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.priviewGoC(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                                slot: "reference",
                                              },
                                              [_vm._v("查看提交")]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-top": "20px" },
                            attrs: { type: "info", size: "medium" },
                            on: {
                              click: function ($event) {
                                return _vm.openBatchStudent()
                              },
                            },
                          },
                          [_vm._v("批量开放")]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            )
          }),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.pagination.currentPage,
              "page-sizes": _vm.pagination.pageSizes,
              "page-size": _vm.pagination.pageSize,
              layout: _vm.pagination.layout,
              total: _vm.pagination.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
          _c("missed-lesson", {
            attrs: {
              applyCourseData: _vm.missLessonCourseData,
              show: _vm.missLessonCourseFlag,
            },
            on: {
              close: function ($event) {
                _vm.missLessonCourseFlag = false
              },
            },
          }),
          _c("preview-file", {
            attrs: { show: _vm.priviewFileDialog, taskID: _vm.filetaskID },
            on: {
              close: function ($event) {
                _vm.priviewFileDialog = false
              },
            },
          }),
          _c("preview-scratch", {
            attrs: {
              show: _vm.priviewScratchDialog,
              taskID: _vm.scratchTaskID,
            },
            on: {
              close: function ($event) {
                _vm.priviewScratchDialog = false
              },
            },
          }),
          _c("marking-exampaper", {
            attrs: {
              class_id: _vm.class_id,
              taskID: _vm.taskID,
              lessonId: _vm.lessonId,
              show: _vm.markingDialog,
            },
            on: {
              close: function ($event) {
                _vm.markingDialog = false
              },
            },
          }),
          _c("set-history-time", {
            attrs: {
              taskId: _vm.taskID,
              lessonId: _vm.lessonId,
              show: _vm.sethistoryDialog,
            },
            on: {
              close: function ($event) {
                _vm.sethistoryDialog = false
              },
            },
          }),
          _c("open-student", {
            attrs: {
              show: _vm.studentDialog,
              ifBatch: _vm.ifBatch,
              task_id: _vm.task_id,
              taskIdArray: _vm.taskIdArray,
            },
            on: {
              close: function ($event) {
                _vm.studentDialog = false
                _vm.ifBatch = false
                _vm.task_id = ""
              },
            },
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }