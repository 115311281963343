var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.ifBatch
        ? _c(
            "el-row",
            { staticClass: "mt-10 mb-10" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.mapTaskAndUser("已启用")
                    },
                  },
                },
                [_vm._v("批量开放")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.mapTaskAndUser("已禁用")
                    },
                  },
                },
                [_vm._v("批量关闭")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-table",
        {
          ref: "type",
          staticStyle: { width: "100%", "margin-top": "15px" },
          attrs: {
            data: _vm.classStudentList,
            "tooltip-effect": "dark",
            border: "",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _vm.ifBatch
            ? _c("el-table-column", {
                key: "selection",
                attrs: { type: "selection", align: "center", width: "70" },
              })
            : _vm._e(),
          _c("el-table-column", {
            key: "loginName",
            attrs: { prop: "loginName", label: "学号", align: "center" },
          }),
          _c("el-table-column", {
            key: "name",
            attrs: { label: "姓名", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.type === "classStudent"
                      ? _c("span", [_vm._v(_vm._s(scope.row.userName))])
                      : _c("span", [_vm._v(_vm._s(scope.row.name))]),
                  ]
                },
              },
            ]),
          }),
          !_vm.ifBatch
            ? _c("el-table-column", {
                key: "operation",
                attrs: { label: "操作", align: "center", width: "120" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-switch", {
                            staticClass: "stu-switch",
                            attrs: {
                              "active-value": true,
                              "inactive-value": false,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.switchCheckItem(
                                  scope.row,
                                  scope.$index
                                )
                              },
                            },
                            model: {
                              value: scope.row.isTaskOpen,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "isTaskOpen", $$v)
                              },
                              expression: "scope.row.isTaskOpen",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3332694853
                ),
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }