var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "components-container" }, [
    _c(
      "div",
      { staticClass: "editor-container" },
      [
        _c("dnd-list", {
          attrs: {
            courseList: _vm.courseList,
            lessonsList: _vm.lessonsList,
            classInfo: _vm.classInfo,
            scrollTopTable: _vm.scrollTopTable,
            courseListTitle: "排课列表",
            lessonsListTitle: "课节列表",
          },
          on: {
            updateView: function ($event) {
              return _vm.getLessonInfoByClassId()
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }