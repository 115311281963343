<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>确认退班</span>
    </div>
    <el-form ref="formData" :model="formData" :rules="rules" label-width="120px">
      <el-form-item label="学员：">
        <span>{{remarkData.userName}}</span>
      </el-form-item>
      <el-form-item label="班级：">
        <span>{{remarkData.className}}</span>
      </el-form-item>
      <el-form-item label="备注：" prop="remark">
        <el-input
          type="textarea"
          :rows="5"
          placeholder="请填写退班原因"
          v-model="formData.remark">
        </el-input>
      </el-form-item>
    </el-form>

    <el-row slot="footer">
      <el-button type="primary" @click="saveLive" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import { leaveClass } from '@/api/senate/class'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    remarkData: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data () {
    return {
      formData: {},
      rules: {
        remark: [
          { required: true, message: '备注不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    openDialog () {
      this.formData = {}
    },

    // 关闭
    close () {
      this.$emit('close')
    },

    saveLive () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          leaveClass({
            classId: this.$route.query.classID,
            userId: this.remarkData.user_id,
            remark: '手动退班:' + this.formData.remark
          }).then(res => {
            if (res.state === 'success') {
              window.$msg('退班成功')
              this.close()
              this.$emit('updateView')
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../style/dialog.scss";
</style>
