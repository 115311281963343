var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "60%",
        top: "5vh",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("开放学员")])]
      ),
      _c(
        "el-tabs",
        {
          attrs: { "tab-position": "left" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        _vm._l(_vm.tabMapOptions, function (item) {
          return _c(
            "el-tab-pane",
            { key: item.key, attrs: { label: item.label, name: item.key } },
            [
              _c("open-pane", {
                key: item.key,
                attrs: {
                  type: item.key,
                  ifBatch: _vm.ifBatch,
                  task_id: _vm.task_id,
                  taskIdArray: _vm.taskIdArray,
                  classStudentList: _vm.classStudentList,
                },
                on: { update: _vm.update },
              }),
            ],
            1
          )
        }),
        1
      ),
      _c("el-row", { attrs: { slot: "footer" }, slot: "footer" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }