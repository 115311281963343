<template>
  <div>
    <el-table
      :data="dataList"
      border
      fit
      highlight-current-row
    >
      <el-table-column align="center" label="姓名" prop="name"></el-table-column>
      <el-table-column align="center" label="事件">
        <template slot-scope="scope">
          <span :class="choose(scope.row.logType)">{{scope.row.logType}}</span>
          <span>--{{scope.row.title}} </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="时间">
        <template slot-scope="scope">
          <span>{{scope.row.time | dateFormat }}</span>
        </template>
      </el-table-column>
    </el-table>
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="paginationall.currentPage"
                        :page-sizes="paginationall.pageSizes"
                        :page-size="paginationall.pageSize"
                        :layout="paginationall.layout"
                        :total="this.totalall"
                ></el-pagination>
  </div>
</template>

<script>
import { getClassStudentState } from '@/api/senate/class'

export default {
  props: {
    classInfo: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data () {
    return {
      dataList: [],
      paginationall: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100],
        layout: "total, sizes, prev, pager, next, jumper",
        total: this.totalall
      },
      totalall: 0
    }
  },
  created () {
    this.getClassStudentState()
  },
  methods: {
    choose(type){
      if(type === '开始任务'){
        return 'begintask'
      }else{
        return 'endtask'
      }

    },
    // 全部分页
    handleCurrentChange(val) {
      this.paginationall.currentPage = val;
      this.getClassStudentState()
    },
    handleSizeChange(val) {
      this.paginationall.pageSize = val;
      this.getClassStudentState()
    },
    async getClassStudentState () {
      this.dataList = []
      const res = await getClassStudentState({
        needCount: true,
        pageNum: this.paginationall.currentPage,
        pageSize:  this.paginationall.pageSize,
        classId: this.$route.query.classID
      })
      this.dataList = res.body.list
      this.totalall = res.body.total
    }
  }
}
</script>

<style lang="scss" scoped>
  .begintask{
    color: #0CA51A ;
  }
  .endtask{
    color: #FF0000;
  }
</style>
