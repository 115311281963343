<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="70%"
    @open="openDialog"
    top="7vh"
    append-to-body
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>学员分班</span>
    </div>
    <!-- classInfo：{{classInfo}} -->
    <!-- seleStuData：{{seleStuData}} -->
    <!-- multipleSelectionTotal：{{multipleSelectionTotal}} -->
    <!-- 全部学员：{{multipleSelectionAll}}
    <hr>
    待分班学员：{{multipleSelectionWait}}
    <hr>
    已选择学员：{{multipleSelectionTotal}} -->
    <el-row :gutter="60">
      <el-col :span="14">
        <el-row style="margin-bottom:20px">
          <!-- <el-select v-model="campusIdSet" multiple placeholder="请选择校区" @change="handleChange">
            <el-option
              v-for="item in campusOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select> -->
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="待分班学员" name="waitList">
              <el-row style="margin-bottom: 10px;">
                <span class="inline-block mr-10">查询全部校区</span>
                <el-switch
                  @change="handleChange"
                  v-model="campusIdSet">
                </el-switch>
              </el-row>
              <el-table
                :data="waitList"
                ref="waitList"
                border
                fit
                highlight-current-row
                :row-key="getRowKeys"
                @selection-change="waithandleselectChange"
              >
                <el-table-column type="selection" width="50" align="center" :reserve-selection="true"></el-table-column>
                <el-table-column label="用户名" align="center" prop="loginName"></el-table-column>
                <el-table-column label="姓名" align="center" prop="name"></el-table-column>
              </el-table>
              <el-pagination
                class="pagination"
                @current-change="waithandleCurrentChange"
                :current-page="waitPagination.currentPage"
                :page-sizes="waitPagination.pageSizes"
                :page-size="waitPagination.pageSize"
                :layout="waitPagination.layout"
                :total="waitPagination.total"
              ></el-pagination>
            </el-tab-pane>
            <!-- 全部学员 -->
            <el-tab-pane label="全部学员" v-if="showAllList"  name="allList">
              <el-row :gutter="20" style="margin-bottom: 10px;">
                <el-col :span="12">
                  <el-input
                    placeholder="请输入内容"
                    v-model="inputVal"
                    class="input-with-select"
                    @keyup.enter.native="search"
                  >
                    <el-select v-model="select" slot="prepend" placeholder="请选择" style="width: 100px;">
                      <el-option label="用户名" value="1"></el-option>
                      <el-option label="姓名" value="2"></el-option>
                    </el-select>
                    <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
                  </el-input>
                </el-col>
                <el-col :span="8">
                  <el-cascader
                    class="td-cascader"
                    v-model="tagSet"
                    :options="tagUserTreeData"
                    :props="props"
                    placeholder="请选择所属标签"
                    clearable></el-cascader>
                </el-col>
              </el-row>
              <el-table
                :data="allList"
                ref="allList"
                border
                fit
                highlight-current-row
                :row-key="getRowKeys"
                @selection-change="allhandleselectChange"
              >
                <el-table-column type="selection" width="70" align="center" :reserve-selection="true"></el-table-column>
                <el-table-column label="用户名" align="center" prop="loginName"></el-table-column>
                <el-table-column label="姓名" align="center" prop="name"></el-table-column>
              </el-table>
              <el-pagination
                class="pagination"
                @current-change="allhandleCurrentChange"
                :current-page="allPagination.currentPage"
                :page-sizes="allPagination.pageSizes"
                :page-size="allPagination.pageSize"
                :layout="allPagination.layout"
                :total="allPagination.total"
              ></el-pagination>
            </el-tab-pane>
            <el-tab-pane label="批量添加" name="batchAdd">
              <el-input
                type="textarea"
                placeholder="S0001
S0002
S0003
……
……
请按每行一个学号的格式填写要添加的学员，点击导入按钮"
                rows="8"
                v-model="batchStr"
                show-word-limit
              >
              </el-input>
              <el-button size="mini" type="primary" @click="importFun" style="margin-top: 10px;">导入</el-button>
            </el-tab-pane>
          </el-tabs>
        </el-row>
      </el-col>
      <el-col :span="10">
        <el-row class="haschoose" >
          <el-row class="seleRow">
            <span>已选择：</span>
            <span class="seleNum" v-text="multipleSelectionTotal.length"></span>
          </el-row>
            <ul style="height: 500px;overflow: auto">
              <li v-for="(item, index) of multipleSelectionTotal" :key="index" class="seleList">
                {{item.name}} [{{item.loginName}}]
              </li>
            </ul>

        </el-row>

        <el-row style="box-shadow:0px 2px 9px 0px rgba(0, 0, 0, 0.15);padding-bottom: 30px;margin-top: 15px;" v-if="activeName === 'batchAdd' && isNonentity">
          <span class="seleRow">不存在的用户名</span>
          <el-row v-for="(item, index) of nonexistence" :key="index" class="seleList">
            <span>{{item}}</span>
          </el-row>
        </el-row>
      </el-col>
    </el-row>
    <el-row slot="footer">
      <el-button type="primary" @click="confirm" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import { getListStudentForClass, setListByLoginNames } from '@/api/senate/class'
import { getUserList } from '@/api/system/staff'
import getTag from '@/mixins/getTag'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    seleStuData: {
      default: () => {
        return []
      },
      type: Array
    },
    isShowAll: {
      default: false,
      type: Boolean
    },
    classInfo: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  watch: {
    multipleSelectionWait (newVal, oldVal) {
      if (newVal.length < oldVal.length) {
        this.clearWaitMerge(newVal, oldVal)
      }
    },
    multipleSelectionAll (newVal, oldVal) {
      if (newVal.length < oldVal.length) {
        this.clearAllMerge(newVal, oldVal)
      }
    }
  },
  mixins: [getTag],
  data () {
    return {
      tagSet:[],
      props: {
        multiple: true,
        value: 'id',
        label: 'title'
      },
      showAllList:false,
      activeName: 'waitList',
      waitList: [], // 待分班学员列表
      allList: [], // 全部学员
      waitPagination: {
        currentPage: 1,
        pageSize: 10,
        layout: 'total, prev, pager, next, jumper',
        total: 0
      },
      allPagination: {
        currentPage: 1,
        pageSize: 10,
        layout: 'total, prev, pager, next, jumper',
        total: 0
      },
      multipleSelectionTotal: [], // 已选择学员
      multipleSelectionWait: [], // 待分班学员
      multipleSelectionAll: [], // 全部学员
      idKey: 'userId',
      inputVal: '',
      select: '',
      campusIdSet: false,
      isNonentity: false,
      batchStr: '',
      nonexistence: [],
    }
  },
  methods: {
    importFun() {
      let loninNameSet = this.batchStr.split('\n')
      setListByLoginNames({
        roleCodeSet: [1],
        loninNameSet
      }).then(res => {
        if (res.state === 'success') {
          this.multipleSelectionTotal = res.body.existence.concat(this.seleStuData)
          // this.getNewList(this.multipleSelectionAll.concat(val).concat(this.seleStuData))
          this.nonexistence = res.body.nonexistence
          if (res.body.nonexistence.length === 0) {
            this.isNonentity = false
          } else {
            this.isNonentity = true
          }
        }
      })
    },

    openDialog () {
      if(localStorage.getItem("perms") != null && localStorage.getItem("perms") != undefined) {
        if(JSON.stringify(localStorage.getItem("perms")).indexOf('class:alldivideStudent') != -1) {
          this.showAllList = true
        }else {
          this.showAllList = false
        }
      }

      this.batchStr = ''
      this.waitPagination.currentPage = 1
      this.allPagination.currentPage = 1
      this.activeName = 'waitList'
      this.isNonentity = false
      this.multipleSelectionWait = []
      this.multipleSelectionAll = []
      this.nonexistence = []
      this.multipleSelectionTotal = this.seleStuData
      // this.chooseLoadList()
      this.getListStudentForClass()
      this.getListStudent()
    },

    handleClick () {
      this.mergeCheck()
      // this.chooseLoadList()
    },

    handleChange () {
      this.getListStudentForClass()
    },

    chooseLoadList () {
      switch (this.activeName) {
        case 'waitList':
          this.getListStudentForClass()
          break
        case 'allList':
          this.getListStudent()
          break
      }
    },

    getRowKeys (row) {
      return row.userId
    },

    // 获取待分班学员列表
    async getListStudentForClass () {
      const res = await getListStudentForClass({
        pageNum: this.waitPagination.currentPage,
        pageSize: this.waitPagination.pageSize,
        classId: this.$route.query.classID,
        campusIdSet: this.campusIdSet ? null : [this.classInfo.campus_id]
      })
      res.body.list.forEach((item, idx) => {
        item.userId = item.id
      })
      this.waitList = res.body.list
      this.waitPagination.total = res.body.total
      this.mergeCheck()
    },

    search () {
      this.allPagination.currentPage = 1
      this.chooseLoadList()
    },

    // 全部学生列表
    async getListStudent () {
      let  tagSet = ''
      if(this.tagSet.length !== 0) {
        const tagSetArr = this.tagSet
        tagSetArr.forEach((ele, idx) => {
          if(tagSet === '') {
            tagSet = ele[ele.length-1]
          }else {
            tagSet = tagSet +','+ ele[ele.length-1]
          }
        })
      }
      const res = await getUserList({
        pageNum: this.allPagination.currentPage,
        pageSize: this.allPagination.pageSize,
        state: 1,
        roleCodeSet: [1],
        loginName: this.select === '1' ? this.inputVal : '',
        name: this.select === '2' ? this.inputVal : '',
        tagIds:tagSet
      })
      this.allList = res.body.list
      this.allPagination.total = res.body.total
      this.mergeCheck()
    },

    // 记忆勾选
    mergeCheck () {
      if (this.activeName === 'waitList') {
        this.waitList.forEach((row, index) => {
          this.multipleSelectionTotal.forEach((ele, idx) => {
            if (row.userId === ele.userId) {
              this.$refs.waitList.toggleRowSelection(this.waitList[index], true)
            }
          })
        })
      } else {
        this.allList.forEach((row, index) => {
          this.multipleSelectionTotal.forEach((ele, idx) => {
            if (row.userId === ele.userId) {
              this.$refs.allList.toggleRowSelection(this.allList[index], true)
            }
          })
        })
      }
    },

    // 清除勾选
    clearWaitMerge (newVal, oldVal) {
      let ids = []
      oldVal.forEach((ele) => {
        ids.push(ele.userId)
      })
      let idKey = this.idKey
      for (let i = 0; i < this.allList.length; i++) {
        for (let j = 0; j < ids.length; j++) {
          if (ids[j].indexOf(this.allList[i][idKey]) >= 0) {
            // 设置选中，记住table组件需要使用ref="table"
            this.$refs.allList.toggleRowSelection(this.allList[i], false)
          }
        }
      }
    },

    clearAllMerge (newVal, oldVal) {
      let ids = []
      oldVal.forEach((ele) => {
        ids.push(ele.userId)
      })
      let idKey = this.idKey
      for (let i = 0; i < this.waitList.length; i++) {
        for (let j = 0; j < ids.length; j++) {
          if (ids[j].indexOf(this.waitList[i][idKey]) >= 0) {
            // 设置选中，记住table组件需要使用ref="table"
            this.$refs.waitList.toggleRowSelection(this.waitList[i], false)
          }
        }
      }
    },

    waithandleselectChange (val) {
      this.multipleSelectionWait = val
      this.multipleSelectionTotal = this.getNewList(this.multipleSelectionAll.concat(val).concat(this.seleStuData))
    },

    allhandleselectChange (val) {
      this.multipleSelectionAll = val
      this.multipleSelectionTotal = this.getNewList(this.multipleSelectionWait.concat(val).concat(this.seleStuData))
    },

    // 关闭
    close () {
      this.$emit('close')
      this.$refs.waitList.clearSelection()
    },

    // 分页
    waithandleCurrentChange (val) {
      this.waitPagination.currentPage = val
      this.getListStudentForClass()
    },

    allhandleCurrentChange (val) {
      this.allPagination.currentPage = val
      this.getListStudent()
    },

    // 保存
    confirm () {
      let thembArray = []
      for (let i = 0; i < this.multipleSelectionTotal.length; i++) {
        let flag = false
        for (let j = 0; j < this.seleStuData.length; j++) {
          if(this.multipleSelectionTotal[i].userId === this.seleStuData[j].userId) {
            flag = true
            break
          }
        }

        if(!flag) {
          thembArray.push(this.multipleSelectionTotal[i])
        }
      }

      this.$nextTick(() => {
        this.$emit('editStuData', thembArray)
        this.close()
      })
    },

    getNewList (arr, userId) {
      const res = new Map()
      return arr.filter((a) => !res.has(a.userId) && res.set(a.userId, 1))
    },

    // 删除学员
    delStu (index, id) {
      if (!this.multipleSelectionTotal || this.multipleSelectionTotal.length <= 0) {
        return
      }
      this.multipleSelectionTotal.splice(index, 1)
      // 标识当前行的唯一键的名称
      let idKey = this.idKey
      let selectAllIds = id
      for (let i = 0; i < this.waitList.length; i++) {
        if (selectAllIds.indexOf(this.waitList[i][idKey]) >= 0) {
          // 设置选中，记住table组件需要使用ref="table"
          this.$refs.waitList.toggleRowSelection(this.waitList[i], false)
        }
      }
      for (let i = 0; i < this.allList.length; i++) {
        if (selectAllIds.indexOf(this.allList[i][idKey]) >= 0) {
          // 设置选中，记住table组件需要使用ref="table"
          this.$refs.allList.toggleRowSelection(this.allList[i], false)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
/deep/ .el-table td {
  padding: 10px 0 !important;
}
/deep/ .el-dialog {
  overflow: auto;
}
.haschoose {
  box-shadow:0px 2px 9px 0px rgba(0, 0, 0, 0.15);padding-bottom: 30px;
}
.seleRow {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  background: #efefef;
  box-sizing: border-box;
  padding-left: 25px;
}
.seleNum {
  color: #fff;
  border-radius: 50%;
  padding: 1px 8px;
  background: #cccccc;
}
.seleList {
  box-sizing: border-box;
  margin-top: 20px;
  padding-left: 20px;
}
.ico-del {
  display: inline-block;
  margin-right: 50px;
  cursor: pointer;
}
</style>
