import { render, staticRenderFns } from "./attendance-detail.vue?vue&type=template&id=07ecf9c1&scoped=true"
import script from "./attendance-detail.vue?vue&type=script&lang=js"
export * from "./attendance-detail.vue?vue&type=script&lang=js"
import style0 from "./attendance-detail.vue?vue&type=style&index=0&id=07ecf9c1&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07ecf9c1",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/frontend_coduck_business_lcPT/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('07ecf9c1')) {
      api.createRecord('07ecf9c1', component.options)
    } else {
      api.reload('07ecf9c1', component.options)
    }
    module.hot.accept("./attendance-detail.vue?vue&type=template&id=07ecf9c1&scoped=true", function () {
      api.rerender('07ecf9c1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/senate/detail-pane/attendance-detail.vue"
export default component.exports