var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "append-to-body": "",
        "close-on-click-modal": false,
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.coursewareList,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "姓名", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.creatorInfo.name) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "学号", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.creatorInfo.loginName) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "提交时间", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(scope.row.createTime.substring(0, 19)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "作品名称", align: "center", prop: "title" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary", plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.previewScratch(scope.row)
                          },
                        },
                      },
                      [_vm._v("查看作品")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.pagination.currentPage,
          "page-sizes": _vm.pagination.pageSizes,
          "page-size": _vm.pagination.pageSize,
          layout: _vm.pagination.layout,
          total: _vm.pagination.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }