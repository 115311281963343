<template>
  <div class="dndList">
    <div :style="{width:width1}" class="dndList-lessonList">
      <!-- courseList:{{courseList}} -->
      <!-- classInfo：{{classInfo}} -->
      <h3>{{ courseListTitle }}</h3>
      <draggable
        :set-data="setData" :list="courseList" v-bind="dragOptionsMneu" class="dragArea" :sort="false"
      >
        <div v-for="(element, index) in courseList" :key="index">
          <div class="mt-15">{{index + 1}}. {{element.start_time | formatDateStart}} {{element.end_time | formatDateEnd}}</div>
          <draggable
            :set-data="setData"
            :list="element.courseUnitlist"
            group="article"
            class="dragArea"
            id="Course"
            :sort="false"
          >
            <div class="list-complete-item" v-for="(item, index) of element.courseUnitlist" :key="index" :id="item.id" :parentId="element.id">
              <!-- item: {{item}} -->
              <div class="list-complete-item-handle">
                <!-- 第{{ index + 1 }}课 - {{ item.title }} -->
                {{ item.title }}
              </div>
              <div style="position:absolute;right:0px;">
                <span style="float: right ;margin-top: -20px;margin-right:5px;" @click="deleteEle(element, index)">
                  <i style="color:#ff4949" class="el-icon-delete" />
                </span>
              </div>
            </div>
          </draggable>
        </div>
      </draggable>
    </div>
    <div :style="{width:width3}" class="dndList-list dndList-btn">
      <el-button type="primary" @click="defaultTouch" :disabled="classInfo.status === '待审核' || Boolean(classInfo.finished_date)">默认关联</el-button>
    </div>
    <div :style="{width:width2}" class="dndList-list right-list">
      <div class="task-fixed" :style="top">
        <h3>{{ lessonsListTitle }}</h3>
        <template v-if="!Boolean(classInfo.finished_date !== '0001-01-01') || !Boolean(classInfo.finished_date !== '1900-01-01') || !Boolean(classInfo.finished_date)">
          <draggable :list="lessonsList" :group="{ name: 'article', pull: 'clone', put: false }" class="dragArea" @end='datadragEnd' id="Lesson">
            <div v-for="(element, index) in lessonsList" :key="index" class="list-complete-item">
              <div class="list-complete-item-handle2">
                第{{ index + 1 }}节 - {{ element.title }}
              </div>
            </div>
          </draggable>
        </template>
        <template v-else>
          <div v-for="(element, index) in lessonsList" :key="index" class="list-complete-item">
            <div class="list-complete-item-handle2">
              第{{ index + 1 }}节 - {{ element.title }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { LessonMapCourseUnits } from '@/api/senate/lesson'

export default {
  name: 'DndList',
  components: { draggable },
  props: {
    scrollTopTable: {
      default: 0,
      type: Number
    },
    classInfo: {
      default: () => {
        return {}
      },
      type: Object
    },
    courseList: {
      type: Array,
      default() {
        return []
      }
    },
    lessonsList: {
      type: Array,
      default() {
        return []
      }
    },
    courseListTitle: {
      type: String,
      default: 'courseList'
    },
    lessonsListTitle: {
      type: String,
      default: 'lessonsList'
    },
    width1: {
      type: String,
      default: '35%'
    },
    width2: {
      type: String,
      default: '40%'
    },
    width3: {
      type: String,
      default: '11%'
    }
  },
  computed: {
    dragOptionsMneu () {
      return {
        group: 'articleParent',
        ghostClass: 'ghost'
      }
    }
  },
  watch: {
    scrollTopTable: {
      handler(val) {
        // console.log('scrollTopTable', val)
        if (val > 200) {
          this.top.top = '130px'
        } else {
          this.top.top = -(val) + 314 + 'px'
        }
      },
    }
  },
  data () {
    return {
      defaultState: false,
      top: {
        top: '314px'
      },
      scrollTop: ''
    }
  },
  methods: {
    deleteEle(ele, idx) {
      let courseList = ele
      let courseUnitIds = []
      this.$confirm('是否删除当前课节', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          courseList.courseUnitlist.splice(idx, 1)
          courseList.courseUnitlist.forEach((item, index) => {
            // courseUnitIds.push(item.id)
            courseUnitIds.push(item.id)
          })
          await LessonMapCourseUnits({
            lessonId: courseList.id,
            courseUnitIds
          })
          window.$msg('删除成功')
          this.$emit('updateView')
        })
    },
    setData(dataTransfer) {
      dataTransfer.setData('Text', '')
    },

    datadragEnd (evt) {
      // console.log('拖动的本身', evt.item)
      console.log('拖动的目标列表', evt.to)
      // console.log('拖动的目标列表 课程id', evt.to.firstChild.getAttribute('parentid'))
      // console.log('拖动之前的列表', evt.from)
      if (evt.to.id !== evt.from.id) {
        let lessonId = evt.to.firstChild.getAttribute('parentid')
        let courseUnitIds = []
        let courseChild = Array.prototype.slice.call(evt.to.children)
        courseChild.forEach((item, index) => {
          courseUnitIds.push(item.getAttribute('id'))
        })
        LessonMapCourseUnits({
          lessonId,
          courseUnitIds
        }).then(res => {
          if (res.state === 'success') {
            window.$msg('添加成功')
            this.$emit('updateView')
          }
        })
      }
    },

    async defaultTouch () {
      this.courseList.forEach(async (item, index) => {
        this.lessonsList.forEach(async (element, idx) => {
          if (index === idx) {
            const res = await LessonMapCourseUnits({
              lessonId: item.id,
              courseUnitIds: [element.id]
            })
            if (res.state === 'success') {
              this.defaultState = true
            } else {
              this.defaultState = false
            }
          }
        })
      })

      setTimeout(() => {
        this.getState()
      }, 800)
    },

    async getState () {
      if (this.defaultState) {
        window.$msg('关联成功')
        this.$emit('updateView')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dndList {
  padding-top: 25px;
  background: #fff;
  min-height: 350px;
  padding-bottom: 40px;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  .dndList-btn {
    text-align: center;
    margin-top: 15px;
  }
  .dndList-list {
    float: left;
    padding-bottom: 30px;
    margin-left: 2%;
    .dragArea {
      margin-top: 15px;
      min-height: 50px;
      padding-bottom: 30px;
    }
  }
  .right-list {
    position: relative;
    .task-fixed {
      position: fixed;
      top: 314px;
      z-index: 999;
      width: 33%;
      max-height: 350px;
      overflow: auto;
    }
  }
  .dndList-lessonList {
    float: left;
    padding-bottom: 30px;
    margin-left: 2%;
    .dragArea {
      padding-left: 15px;
      // min-height: 40px;
      margin-top: 10px;
    }
  }
}
.list-complete-item {
  cursor: pointer;
  position: relative;
  font-size: 14px;
  padding: 5px 12px;
  margin-top: 4px;
  border: 1px solid #bfcbd9;
  transition: all 1s;
}

.list-complete-item-handle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 50px;
}

.list-complete-item-handle2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 20px;
}

.list-complete-item.sortable-chosen {
  background: #F5C319;
}

.list-complete-item.sortable-ghost {
  background: #F5C319;
  // color: #fff;
}

.list-complete-enter,
.list-complete-leave-active {
  opacity: 0;
}

.ghost {
  border: 1px solid #aaaaaa;
  padding: 0 15px 15px 15px;
  margin-top: 15px;
}
</style>
