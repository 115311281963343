<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="60%"
    top="5vh"
    @open="openDialog"
    append-to-body
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>开放学员</span>
    </div>
    <!-- task_id：{{task_id}} -->
    <!-- <hr> -->
    <!-- taskIdArray：{{taskIdArray}} -->
    <!-- classStudentList: {{classStudentList}} -->
    <el-tabs v-model="activeName" tab-position="left">
      <el-tab-pane v-for="item in tabMapOptions" :key="item.key" :label="item.label" :name="item.key">
        <open-pane
          :type="item.key"
          :ifBatch="ifBatch"
          :task_id="task_id"
          :taskIdArray="taskIdArray"
          :classStudentList="classStudentList"
          :key="item.key"
          @update="update"
        ></open-pane>
      </el-tab-pane>
    </el-tabs>

    <el-row slot="footer">
      <!-- <el-button size="small" @click="save" type="primary">保 存</el-button>
      <el-button size="small" type="info" @click="close">取 消</el-button> -->
    </el-row>
  </el-dialog>
</template>

<script>
import {getLessonStudentWithTaskStatus, getClassStudentListWithTaskStatus, getLessonInfoByClassId, getLessionStudentMessage} from '@/api/senate/class'
import { formatDate } from '@/utils/formatDate'
import openPane from './open-pane'

export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    ifBatch: {
      default: false,
      type: Boolean
    },
    task_id: {
      default: '',
      type: String
    },
    taskIdArray: {
      default: () => {
        return []
      },
      type: Array
    }
  },
  watch: {
    activeName (newVal, oldVal) {
      if (newVal === 'classStudent') {
        this.getClassStudentListWithTaskStatus()
      } else {
        if (!this.ifBatch) {
          this.getLessonStudentWithTaskStatus(newVal)
        } else {
          this.getLessionStudentMessage(newVal)
        }
      }
    },
  },
  components: { openPane },
  data() {
    return{
      classStudentList: [],
      classStudent: [],
      lessonList: [],
      activeName: 'classStudent',
      tabMapOptions: [
        {
          key: 'classStudent',
          label: '本班学员'
        }
      ]
    }
  },
  methods: {
    openDialog() {
      this.activeName = 'classStudent'
      this.tabMapOptions = [
        {
          key: 'classStudent',
          label: '本班学员'
        }
      ]
      this.getClassStudentListWithTaskStatus()
      this.getLessonInfoByClassId()
    },

    update() {
      if (this.activeName === 'classStudent') {
        this.getClassStudentListWithTaskStatus()
      } else {
        this.getLessonStudentWithTaskStatus(this.activeName)
      }
    },

    async getClassStudentListWithTaskStatus() {
      const res = await getClassStudentListWithTaskStatus({
        classId: this.$route.query.classID,
        taskId: this.task_id
      })
      this.classStudent = res.body
      this.classStudentList = this.classStudent
    },

    async getLessonInfoByClassId(){
      const res = await getLessonInfoByClassId({
        class_id: this.$route.query.classID,
      })
      this.lessonList = res.body.list
      res.body.list.forEach((ele, idx) => {
        this.tabMapOptions.push(
          {
            key: ele.id,
            label: formatDate(new Date(ele.start_time), 'yyyy-MM-dd hh:mm~')+formatDate(new Date(ele.end_time), 'hh:mm')
          }
        )
      });
    },

    async getLessonStudentWithTaskStatus(lesson_id) {
      const res = await getLessonStudentWithTaskStatus({
        lessonId: lesson_id,
        taskId: this.task_id
      })
      this.classStudentList = res.body
    },
    
    async getLessionStudentMessage(lesson_id) {
      const res = await getLessionStudentMessage({
        lesson_id
      })
      this.classStudentList = res.body
    },

    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../style/dialog.scss';
</style>
