<template>
  <div>
    <!-- type: {{type}}
    classInfo：{{classInfo}} -->
    <!-- classLessonList：{{classLessonList}} -->
    <!-- scrollTopTable: {{scrollTopTable}} -->
    <!-- classStudent:{{classStudent}} -->
    <class-info v-if="type === 'Info'" :classInfo="classInfo" @updateView="updateView"></class-info>
    <class-student v-else-if="type === 'Student'" :classInfo="classInfo" :classStudent="classStudent"></class-student>
    <class-student-state v-else-if="type === 'ClassStudentState'" :classInfo="classInfo"></class-student-state>
    <class-catalogue  v-else-if="type === 'Catalogue'" :classInfo="classInfo"></class-catalogue>
    <class-lesson v-else-if="type === 'Lesson'" :classInfo="classInfo"></class-lesson>
    <class-relevancy v-else-if="type === 'Relevancy'" :classInfo="classInfo" :scrollTopTable="scrollTopTable"></class-relevancy>
    <!-- <class-live v-else-if="type === 'live'" :classInfo="classInfo"></class-live> -->
  </div>
</template>

<script>
import ClassInfo from './class-info'
import ClassStudent from './class-student'
import ClassStudentState from './class-student-state'
import ClassCatalogue from './class-catalogue'
import ClassLesson from './class-lesson'
import ClassRelevancy from './class-relevancy'
// import ClassLive from './class-live'

export default {
  props: {
    type: {
      default: 'Info',
      type: String
    },
    classInfo: {
      default: () => {
        return {}
      },
      type: Object
    },
    classStudent: {
      default: () => {
        return []
      },
      type: Array
    },
    scrollTopTable: {
      default: 0,
      type: Number
    }
  },
  components: {
    ClassInfo,
    ClassStudent,
    ClassStudentState,
    ClassCatalogue,
    ClassLesson,
    ClassRelevancy,
    // ClassLive
  },
  data () {
    return {}
  },
  methods: {
    updateView () {
      this.$emit('getClassTeacherInfoById')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
