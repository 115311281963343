<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>课程进度详情</span>
    </div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="按课节" name="first">
        <el-tree :data="lessonsData" :props="defaultProps" :default-expand-all="true"></el-tree>
      </el-tab-pane>
      <el-tab-pane label="按类型" name="second">
        <el-tree :data="typeData" :props="defaultProps" :default-expand-all="true"></el-tree>
      </el-tab-pane>
    </el-tabs>
    <el-row slot="footer">
      <el-row style="text-align: right;margin-bottom: 15px;">
        <el-button type="primary" @click="close">确 定</el-button>
        <el-button @click="close" type="info">取 消</el-button>
      </el-row>
    </el-row>
  </el-dialog>
</template>

<script>
import { getTaskList } from '@/api/research/curriculum'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    course_id: {
      default: '',
      type: String
    },
    user_id: {
      default: '',
      type: String
    },
    class_id: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      activeName: 'first',
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      lessonsData: [],
      lessonsList: [],
      typeData: [],
      typeList: [],
      curriculumList: []
    }
  },
  methods: {
    openDialog () {
      this.lessonsData = []
      this.lessonsList = []
      this.typeData = []
      this.typeList = []
      this.getlessonsData()
    },

    // 数据重组
    async getlessonsData () {
      // 获取(课节, 任务) 列表
      const res2 = await getTaskList({
        pageNum: 1,
        pageSize: 1000,
        classId: this.class_id,
        studentId: this.user_id,
        status: '已启用'
      })
      res2.body.list.forEach((ele, idx) => {
        if (!ele.startTime) {
          ele.title = ele.title + ' （未开始）'
        } else if (ele.startTime && !ele.finishTime) {
          ele.title = ele.title + ' （未完成）'
        } else if (ele.finishTime) {
          ele.title = ele.title + ' （已完成）'
        }
        return (
          this.typeList.includes(ele.type) ? '' : this.typeList.push(ele.type),
          this.lessonsList.includes(ele.courseUnitId) ? '' : this.lessonsList.push(ele.courseUnitId)
        )
      })
      this.taskList = res2.body.list

      // 整合数据 -> （按课节）

      this.lessonsList.forEach((ele, idx) => {
        this.lessonsData.push({
          id: ele
        })
      })

      // console.log('lessonsData', this.lessonsData)

      // this.lessonsData = [...this.curriculumList]
      for (let index in this.lessonsData) {
        for (let i in this.taskList) {
          if (this.lessonsData[index].id === this.taskList[i].courseUnitId) {
            this.lessonsData[index].title = this.taskList[i].courseUnitTitle
            if (!this.lessonsData[index].children) {
              this.lessonsData[index].children = []
            }
            this.lessonsData[index].children.push(this.taskList[i])
          }
        }
      }

      let lessonTotalNum = 0
      this.lessonsData.forEach((ele, idx) => {
        let lessonAttendNum = 0
        this.lessonsData[idx].children.forEach((ele1, idx1) => {
          lessonTotalNum = this.lessonsData[idx].children.length
          if (!ele1.finishTime) {
          } else {
            lessonAttendNum++
          }
        })
        this.lessonsData[idx].title = this.lessonsData[idx].title + ' （' + lessonAttendNum + '/' + lessonTotalNum + '）'
      })

      // 整合数据 -> （按类型）
      this.typeList.forEach((ele, idx) => {
        this.typeData.push({
          title: ele
        })
      })

      for (let index in this.typeData) {
        for (let i in this.taskList) {
          if (this.typeData[index].title === this.taskList[i].type) {
            if (!this.typeData[index].children) {
              this.typeData[index].children = []
            }
            // console.log('taskList', this.taskList[i])
            this.typeData[index].children.push(this.taskList[i])
          }
        }
      }

      let taskCompleteNum = 0 // 任务总数
      this.typeData.forEach((ele, idx) => {
        let taskTNum = 0 // 任务总量
        this.typeData[idx].children.forEach((ele1, idx1) => {
          taskCompleteNum = this.typeData[idx].children.length
          if (!ele1.finishTime) {
          } else {
            taskTNum++
          }
        })
        this.typeData[idx].title = this.typeData[idx].title + ' （' + taskTNum + '/' + taskCompleteNum + '）'
      })

      // console.log('typeData', this.typeData)
    },

    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
</style>
