<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>出勤详情</span>
    </div>
    <el-table
      :data="dataList"
      border
      fit
      highlight-current-row
    >
      <el-table-column align="center" label="序号">
        <template slot-scope="scope">
          <span>{{scope.$index + 1}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="上课时间">
        <template slot-scope="scope">
          <span>{{ scope.row.start_time | formatDateStart }}</span>
          <span>{{ scope.row.end_time | formatDateEnd }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="老师" prop="zjName"></el-table-column>
      <el-table-column align="center" label="出勤状态" prop="">
        <template slot-scope="scope">
          <span v-if="scope.row.finished_status === '未上课'">未开课</span>
          <span v-if="scope.row.finished_status === '已上课' && scope.row.is_attend === '是'">已出勤</span>
          <span v-if="scope.row.finished_status === '已上课' && scope.row.is_attend === '否'">未出勤</span>
        </template>
      </el-table-column>
    </el-table>
    <el-row slot="footer">
      <el-row style="text-align: right;margin-bottom: 15px;">
        <el-button type="primary" @click="close">确 定</el-button>
        <el-button @click="close" type="info">取 消</el-button>
      </el-row>
    </el-row>
  </el-dialog>
</template>

<script>
import { getLessonByStudent } from '@/api/senate/class'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    class_id: {
      default: '',
      type: String
    },
    user_id: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      dataList: []
    }
  },
  methods: {
    openDialog () {
      this.getLessonByStudent()
    },

    async getLessonByStudent () {
      const res = await getLessonByStudent({
        class_id: this.class_id,
        student_id: this.user_id
      })
      this.dataList = res.body
    },

    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
</style>
