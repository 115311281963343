<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>消课数量管理</span>
    </div>
    <el-table :data="dataList" border fit highlight-current-row class="tb-list">
      <!-- <el-table-column align="center" label="序号" prop="num" width="80"></el-table-column> -->
      <el-table-column align="center" label="上课时间">
        <template slot-scope="scope">
          {{scope.row.start_time | formatDateStart}} {{scope.row.end_time | formatDateEnd}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="状态" width="150">
        <template slot-scope="scope">
          <span
            :style="'color'+':'+getStateColor(scope.row.finished_status)"
          >{{ scope.row.finished_status}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="消课数量">
        <template slot-scope="scope">
          <el-input v-model="scope.row.consume_time" placeholder="请输入单次消费数量" :disabled="scope.row.finished_status === '已上课'"></el-input>
          <!-- <el-input-number v-model="scope.row.consume_time" :min="1" ></el-input-number> -->
        </template>
      </el-table-column>
    </el-table>
    <el-row slot="footer">
      <el-row style="text-align: right;margin-bottom: 15px;">
        <el-button type="primary" @click="confirm">确 定</el-button>
        <el-button @click="close" type="info">取 消</el-button>
      </el-row>
    </el-row>
  </el-dialog>
</template>

<script>
import { getListLessonConsume, editConsumeBat } from '@/api/senate/lesson'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      dataList: []
    }
  },
  methods: {
    openDialog () {
      this.getListLessonConsume()
    },

    async getListLessonConsume () {
      const res = await getListLessonConsume({
        classId: this.$route.query.classID
      })
      this.dataList = res.body
    },
    // 关闭
    close () {
      this.$emit('close')
    },

    getStateColor (state) {
      switch (state) {
        case '已上课':
          return '#008000'
        case '未上课':
          return '#FF0000'
        default:
          return '#f5c319'
      }
    },
    confirm () {
      const lessonArrayList = []
      this.dataList.forEach((ele, idx) => {
        lessonArrayList.push({
          id: ele.id,
          consume_time: ele.consume_time
        })
      })
      editConsumeBat({
        lessonArrayList: lessonArrayList
      }).then(res => {
        if (res.state === 'success') {
          // window.$msg('消课成功')
          window.$msg('消课数量设置成功')
          this.close()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
</style>
