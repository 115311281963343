var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "50%",
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("出入班记录")])]
      ),
      _c(
        "el-row",
        [
          _c(
            "el-table",
            {
              staticClass: "tb-list",
              attrs: {
                data: _vm.dataList,
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "学员名", align: "center", prop: "userName" },
              }),
              _c("el-table-column", {
                attrs: { label: "出入班", align: "center", prop: "type" },
              }),
              _c("el-table-column", {
                attrs: { label: "时间", align: "center", prop: "createTime" },
              }),
              _c("el-table-column", {
                attrs: { label: "备注", align: "center", prop: "remark" },
              }),
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.pagination.currentPage,
              "page-sizes": _vm.pagination.pageSizes,
              "page-size": _vm.pagination.pageSize,
              layout: _vm.pagination.layout,
              total: this.pagination.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }