<template>
  <div>
    <el-row class="mb-20">
      <el-button @click="editClass" size="small" type="primary" v-has="'class:editClassInfo'" :disabled="Boolean(classInfo.finished_date) && (classInfo.finished_date !== '0001-01-01') && (classInfo.finished_date !== '1900-01-01')">编 辑</el-button>
      <el-button @click="userTeacherDialog = true" size="small" type="primary" v-has="'class:editClassInfo'" :disabled="Boolean(classInfo.finished_date) && (classInfo.finished_date !== '0001-01-01') && (classInfo.finished_date !== '1900-01-01')">更新任课老师</el-button>
      <el-button @click="userAssistantDialog = true" size="small" type="primary" v-has="'class:editClassInfo'" :disabled="Boolean(classInfo.finished_date) && (classInfo.finished_date !== '0001-01-01') && (classInfo.finished_date !== '1900-01-01')">更新助教</el-button>

      <el-button @click="updateCourse = true" size="small" type="primary" :disabled="Boolean(classInfo.finished_date) && (classInfo.finished_date !== '0001-01-01') && (classInfo.finished_date !== '1900-01-01')" v-has="'class:updateTeachCourse'" >更新授课课程</el-button>
    </el-row>
    <el-card style="margin-bottom:10px;">
<!--       classInfo：{{classInfo}}-->
      <!-- product_id: {{product_id}} -->
      <div style="margin:10px 0;" v-if="product_id">
        所属课程：
        <span v-if="classInfo" v-text="classInfo.productName"></span>
      </div>
      <div style="margin:10px 0;" >
        所属校区：
        <span v-if="classInfo" v-text="classInfo.claCampus"></span>
      </div>
      <div>
        班级名称：
        <span v-if="classInfo" v-text="classInfo.className"></span>
      </div>
      <div style="margin:10px 0;">
        任课老师：
        <span v-if="classInfo" v-text="classInfo.zjName"></span>
      </div>
      <div>
        助教老师：
        <span v-if="classInfo">
          <span :key="index" v-for="(item, index) of this.classInfo.assistant">
            <span>{{item}}</span>
          </span>
        </span>
      </div>
      <div style="margin:10px 0;">
        班级类型：
        <span >
          <span>{{this.classInfo.type}}</span>
        </span>
      </div>
      <div style="margin:10px 0;">
        班容类型：
        <span >
          <span>{{this.classInfo.lctype}}</span>
        </span>
      </div>
      <div style="margin:10px 0;">
        授课课程：
        <span v-if="classInfo && this.classInfo.course != null">
          <span>{{this.classInfo.course.title}}</span>
        </span>
      </div>
      <div style="margin:10px 0;">
        默认教室：
        <span v-if="classInfo && this.classInfo.classroomId != null">
          <span>{{this.classInfo.classroomTitle}}</span>
        </span>
      </div>
    </el-card>

    <!-- 修改班级信息dialog -->
    <add-class
      :class_id="class_id"
      :product_id="product_id"
      :ordinaryClass="ordinaryClass"
      :classInfo="classInfo"
      :schoolArray="schoolArray"
      :show="dialogClass"
      @close="dialogClass=false"
      @editClassForm="editClassForm"
      @getTeacherMessage="getTeacherMessage"
    ></add-class>

    <!-- 选择老师 dialog -->
    <sele-teacher
      :show="userTeacherDialog"
      @close="userTeacherDialog=false"
      entryType = "teacher"
      @seleTeacherData="seleTeacherData"
      :lessTeacher="lessTeacher"
    ></sele-teacher>
    <sele-assistant
      :show="userAssistantDialog"
      @close="userAssistantDialog=false"
      @seleAssistantData="seleAssistantData"
      ref="assistant"
      :lessAssistant="lessAssistant"
    ></sele-assistant>
    <update-class-course
      :show="updateCourse"
      @close="updateCourse=false"
      :classInfo="classInfo"
      :classId = 'class_id'
      @updateView = 'updateDate'
    ></update-class-course>
  </div>
</template>

<script>
import AddClass from '@/components/senate/add-class'
import seleTeacher from '@/components/senate/sele-teacher'
import seleAssistant from '@/components/senate/sele-assistant'
import { editClassData,replaceTeacher } from '@/api/senate/class'
import getMyCampus from '@/mixins/getMyCampus'
import updateClassCourse from '@/components/senate/updateClassCourse'

export default {
  props: {
    classInfo: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  mixins: [getMyCampus],
  watch: {
    classInfo: {
      handler (val) {
        // 数据同步
      },
      immediate: true,
      deep: true
    }
  },
  components: { AddClass,seleTeacher,seleAssistant,updateClassCourse },
  data () {
    return {
      dialogClass: false,
      ordinaryClass: false,
      updateCourse:false,
      lessTeacher:[],
      lessAssistant:[],
      userTeacherDialog: false,
      userAssistantDialog:false,
      class_id: this.$route.query.classID, // 班级id
      product_id: this.$route.query.productId // 收费课程id
    }
  },
  methods: {
    // 编辑班级
    editClass () {
      this.ordinaryClass = true
      this.dialogClass = true
    },
    // 老师
    seleTeacherData (data) {
      this.lessTeacher = [ ...data ]
      if (this.$route.query.classID) {
        let userIdSet = []
        data.map(item => {
          userIdSet.push(item.userId)
        })
        replaceTeacher({
          classId: this.$route.query.classID,
          type: '主教',
          userIdSet: userIdSet
        }).then(res => {
          if (res.state === 'success') {
            window.$msg('更新老师成功')
            this.getTeacherMessage()
          }
        })
      }
    },
    seleAssistantData (data) {
      this.lessAssistant = [ ...data ]
      if (this.$route.query.classID) {
        let userIdSet = []
        data.map(item => {
          userIdSet.push(item.userId)
        })
        replaceTeacher({
          classId: this.$route.query.classID,
          type: '助教',
          userIdSet: userIdSet
        }).then(res => {
          if (res.state === 'success') {
            window.$msg('更新助教成功')
            this.getTeacherMessage()
          }
        })
      }
    },
    updateDate() {
      this.$emit('updateView')
    },
    // 编辑班级信息
    async editClassForm (data) {
      await editClassData({
        id: this.class_id,
        name: data.name,
        open_date:
          typeof data.open_date === 'object'
            ? data.open_date
            : data.open_date,
        campus_id: data.campus_id,
        lctype:data.lctype,
        type:data.type,
        classroom_id: data.classroom_id ? data.classroom_id[0] : '',
        course_id:
          data.status === '已启用'
            ? null
            : data.catalogId
            ? data.catalogId[data.catalogId.length - 1]
            : null,
      })
      window.$msg('编辑班级成功')
      this.$emit('updateView')
    },

// dateKey 时间处理
    datefmt (date) {
      let res = ''
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }
      let day = date.getDate()
      if (day < 10) {
        day = '0' + day
      }
      res = `${year}-${month}-${day}`
      return res
    },

    getTeacherMessage () {
      this.$emit('updateView')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
