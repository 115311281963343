<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    append-to-body
    :close-on-click-modal="false"
    top="7vh"
  >
    <el-table :data="coursewareList" border fit highlight-current-row>
      <el-table-column label="姓名" align="center">
        <template slot-scope="scope">
          {{scope.row.creatorInfo.name}}
        </template>
      </el-table-column>
      <el-table-column label="学号" align="center">
        <template slot-scope="scope">
          {{scope.row.creatorInfo.loginName}}
        </template>
      </el-table-column>

      <el-table-column label="提交时间" align="center">
        <template slot-scope="scope">
          {{scope.row.createTime.substring(0,19)}}
        </template>
      </el-table-column>
      <el-table-column label="作品名称" align="center" prop="title"></el-table-column>
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <el-button size="small" type="primary" plain @click="previewScratch(scope.row)">查看作品</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>
  </el-dialog>
</template>

<script>
  import { scratchList } from '@/api/research/scratch'

  export default {
    name:"PreviewAccessory",
    props: {
      show: {
        default: false,
        type: Boolean
      },
      taskID: {
        default: "",
        type: String
      },
    },
    data () {
      return {
        pagination: {
          currentPage: 1,
          pageSize: 10,
          pageSizes: [10, 30, 50, 100],
          layout: 'total, sizes, prev, pager, next, jumper',
          total: 0
        },
        resetUse: {},
        coursewareList:[],
      }
    },
    methods: {
      previewScratch(row) {
        const routeData = this.$router.resolve({
          path: '/research/editScratchMould',
          query: {
            file: row.file,
            pageType:"previewStudentScratch",
          }
        })
        window.open(routeData.href,"_blank")
      },
      // 分页
      handleCurrentChange (val) {
        this.pagination.currentPage = val
        this.scratchList()
      },
      handleSizeChange (val) {
        this.pagination.pageSize = val
        this.scratchList()
      },
      openDialog () {
        this.scratchList()
      },

      async scratchList () {
        const res = await scratchList(
          {
            taskId: this.taskID,
            classId: this.$route.query.classID,
            pageNum: this.pagination.currentPage,
            pageSize: this.pagination.pageSize,
          })
        this.pagination.total = res.body.total
        this.coursewareList = res.body.list
      },

      close () {
        this.$emit('close')
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../style/dialog.scss";
</style>
