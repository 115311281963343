<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="5vh"
  >
    <el-steps :active="active" align-center>
      <el-step title="步骤1" description="请确认班级成员"></el-step>
      <el-step title="步骤2" description="请确认班级基本信息、班级类型、授课课程"></el-step>
      <el-step title="步骤3" description="请确认审核通过"></el-step>
    </el-steps>
    <el-row v-if="active === 0">
      <el-button
        @click="syncClassData"
        size="small"
        class="tb-button"
        style="margin-bottom: 15px;"
        type="primary"
      >同步本班数据</el-button>
      <el-table
        :data="dataList"
        border
        fit
        highlight-current-row
      >
        <el-table-column align="center" label="学号" prop="loginName"></el-table-column>
        <el-table-column align="center" label="姓名" prop="userName"></el-table-column>
        <el-table-column align="center" label="入班日期">
          <template slot-scope="scope">
            <span>{{scope.row.createTime | dateFormat}}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row v-if="active === 1">
      <div style="margin:10px 0;">
        收费项目：
        <span v-if="classInfo" v-text="classInfo.productName"></span>
      </div>
      <div>
        班级名称：
        <span v-if="classInfo" v-text="classInfo.className"></span>
      </div>
      <div style="margin:10px 0;" >
        所属校区：
        <span v-if="classInfo" v-text="classInfo.claCampus"></span>
      </div>
      <div style="margin:10px 0;">
        任课老师：
        <span v-if="classInfo" v-text="classInfo.zjName"></span>
      </div>
      <div>
        助教老师：
        <span v-if="classInfo">
          <span :key="index" v-for="(item, index) of this.classInfo.assistant">
            <span>{{item}}</span>
          </span>
        </span>
      </div>
      <div style="margin:10px 0;">
        默认教室：
        <span v-if="classInfo && this.classInfo.classroomId != null">
          <span>{{this.classInfo.classroomTitle}}</span>
        </span>
      </div>
      <div>
        班容类型：
        <span>
          <span>
            <el-select
              v-model="classLctype"
              placeholder="请选择班容类型"
              clearable
              filterable
            >
            <el-option
              v-for="item in lctypeArray"
              :key="item.value"
              :label="item.value"
              :value="item.value"
            ></el-option>
          </el-select>
          </span>
        </span>
      </div>
      <div style="margin-top: 10px">
        班级类型：
        <span v-if="!showChangeClassType">
          <span >
            <span>{{this.classInfo.type}}</span>
          </span>
          <span>
            <el-button type="text" style="margin-left: 20px;" @click="changeClassType(true)">修改</el-button>
          </span>
        </span>
        <span v-else>
          <span>
            <el-select
              v-model="classType"
              @change="handleTypeChange"
              placeholder="请选择班级类型"
              clearable
              filterable
            >
            <el-option
              v-for="item in typeArray"
              :key="item.value"
              :label="item.value"
              :value="item.value"
            ></el-option>
          </el-select>
          </span>
          <span>
            <el-button type="text" style="margin-left: 20px;" @click="editClassForm">确定</el-button>
             <el-button type="text" style="margin-left: 20px;" @click="changeClassType(false)">取消</el-button>
          </span>
        </span>
      </div>
      <div>
        授课课程：
        <span v-if="!showChangeClassCourse">
          <span v-if="classInfo && classInfo.course != null">
            <span>{{this.classInfo.course.title}}</span>
          </span>
           <span>
               <el-button  v-if="classInfo.course !== null" type="text" style="margin-left: 20px;" @click="changeClassCourse(true)">修改</el-button>
            <el-button  v-if="classInfo.course === null"type="text" style="margin-left: 20px;" @click="changeClassCourse(true)">选择课程</el-button>
          </span>
        </span>

        <span v-else>
          <span>
             <el-cascader
               style="width: 40%"
               clearable
               v-model="courseId"
               :options="tableArr"
               :props="{ checkStrictly: true, value: 'id', label: 'title' }"
             ></el-cascader>
          </span>
           <span>
            <el-button type="text" style="margin-left: 20px;" @click="editClassCourse">确定</el-button>
             <el-button type="text" style="margin-left: 20px;" @click="changeClassCourse(false)">取消</el-button>
          </span>

        </span>
      </div>
    </el-row>
    <el-row  v-if="active === 2">
      <div style="margin:10px 0;">
        收费项目：
        <span v-if="classInfo" v-text="classInfo.productName"></span>
      </div>
      <div>
        班级名称：
        <span v-if="classInfo" v-text="classInfo.className"></span>
      </div>
      <div style="margin:10px 0;" >
        所属校区：
        <span v-if="classInfo" v-text="classInfo.claCampus"></span>
      </div>
      <div style="margin:10px 0;">
        任课老师：
        <span v-if="classInfo" v-text="classInfo.zjName"></span>
      </div>
      <div>
        助教老师：
        <span v-if="classInfo">
          <span :key="index" v-for="(item, index) of this.classInfo.assistant">
            <span>{{item}}</span>
          </span>
        </span>
      </div>
      <div style="margin:10px 0;">
        默认教室：
        <span v-if="classInfo && this.classInfo.classroomId != null">
          <span>{{this.classInfo.classroomTitle}}</span>
        </span>
      </div>
      <div style="margin:10px 0;">
        班级类型：
        <span >
          <span>{{this.classInfo.type}}</span>
        </span>
      </div>
      <div >
        授课课程：
        <span>
          <span>{{this.classInfo.course.title}}</span>
        </span>
      </div>
    </el-row>
    <el-button style="margin-top: 12px;" @click="next">{{nextTitle}}</el-button>
  </el-dialog>
</template>

<script>
  import { getClassStudentInfoById,editClassCourse,editClassData ,classReview} from '@/api/senate/class'
  import { updateClassMember } from '@/api/system/sync'
  import getCourseType from '@/mixins/getCouresTypeLess'
    export default {
      name: "index",
      components: { },
      mixins: [getCourseType],
      props: {
        classInfo: {
          default: null,
          type: Object,
        },
        show: {
          default: false,
          type: Boolean,
        },
        classId: {
          default:'',
          type:String
        }
      },
      data() {
        return {
          active: 0,
          nextTitle:'下一步',
          dataList:[],
          courseId:[],
          classType:'',
          classLctype:'',
          lctypeArray:[],
          typeArray:[
            {
              value:'常规班'
            },{
              value:'竞赛班'
            },{
              value:'信竞班'
            },{
              value:'活动课'
            },{
              value:'校内趣味社团'
            },{
              value:'校内研习社团'
            },{
              value:'考试测试'
            },{
              value:'企业培训'
            },{
              value:'备课班'
            }
          ],
          showChangeClassType:false,
          showChangeClassCourse:false,
        }
      },
      methods: {
        handleTypeChange() {
          this.lctypeArray = []
          this.classLctype = ''
          switch (this.classType) {
            case '常规班':
              this.lctypeArray = [
                {
                  value:'常规'
                },{
                  value:'金牌班'
                },
              ]
              break;
            case '竞赛班':
              this.lctypeArray = [
                {
                  value:'竞赛'
                }
              ]
              break;
            case '信竞班':
              this.lctypeArray = [
                {
                  value:'信竞'
                }
              ]
              break;
          }
        },
        openDialog() {
          this.active = 0
          this.nextTitle = '下一步'
          this.showChangeClassType = false
          this.showChangeClassCourse = false
          if(this.classInfo.type === null) {
            this.showChangeClassType = true
          }
          this.classLctype = this.classInfo.lctype
          switch (this.classInfo.type) {
            case '常规班':
              this.lctypeArray = [
                {
                  value:'常规'
                },{
                  value:'金牌班'
                },
              ]
              break;
            case '竞赛班':
              this.lctypeArray = [
                {
                  value:'竞赛'
                }
              ]
              break;
            case '信竞班':
              this.lctypeArray = [
                {
                  value:'信竞'
                }
              ]
              break;
          }
          this.getClassStudentInfoById()
        },
        async editClassCourse() {
          if(this.courseId.length === 0) {
            window.$msg('请先选择授课课程',2)
            return  false
          }
          const res = await editClassCourse({
            id:this.classId,
            course_id:this.courseId [this.courseId.length - 1]
          })
          if(res && res.state === 'success') {
            window.$msg('更新授课课程成功')
            this.showChangeClassCourse = false
            this.$emit('getClassTeacherInfoById')
          }
        },
        // 修改班级类型
        async editClassForm () {
          if(this.classType === '') {
            window.$msg('请先选择班级类型',2);
            return  false
          }else {
            if(this.classType === '常规班' && this.classLctype !== '常规' && this.classLctype !== '金牌班'){
              window.$msg('班级类型和班容类型不符',2);
              return  false
            }else if(this.classType === '竞赛班' && this.classLctype !== '竞赛') {
              window.$msg('班级类型和班容类型不符',2);
              return  false
            }else if(this.classType === '信竞班' && this.classLctype !== '信竞') {
              window.$msg('班级类型和班容类型不符',2);
              return  false
            }
          }
          await editClassData({
            id: this.classId,
            type:this.classType,
            lctype:this.classLctype
          })
          window.$msg('修改班级类型成功')
          this.showChangeClassType = false
          this.$emit('getClassTeacherInfoById')
        },
        changeClassCourse(flag) {
          this.showChangeClassCourse = flag
        },
        changeClassType(flag) {
          if (flag) {
            this.classType = this.classInfo.type
          }
          this.showChangeClassType = flag
        },
        async getClassStudentInfoById () {
          const res = await getClassStudentInfoById({
            class_id: this.classId
          })
          this.dataList = res.body
        },
        // 同步本班数据
        async syncClassData () {
         const response = await updateClassMember({ classId: this.classId }) // 更新班级成员
          if(response && response.state === 'success') {
            this.getClassStudentInfoById()
            window.$msg('同步数据完毕')
          }
        },

        next() {
          if(this.active !== 1) {
            this.active++
          }else {
            if(this.classInfo.type === null) {
              window.$msg('请先选择班级类型',2)
              return false
            }else if(this.classInfo.course === null) {
              window.$msg('请先选择授课课程',2)
              return false
            }else if(this.classInfo.lctype === null) {
              window.$msg('请先选择班容类型',2)
              return false
            }
            this.active++
          }
          if(this.active === 3) {
            this.classReview()
          }
          if (this.active > 2) this.active = 2;
          switch (this.active) {
            case 0:
              this.nextTitle = '下一步'
              break;
            case 1:
              this.nextTitle = '下一步'
              break;
            case 2:
              this.nextTitle = '审核通过'
              break;
          }
        },

        close() {
          this.$emit('close')
        },

        async classReview() {
          this.$confirm('审核通过后，班级所选课程无法再变更，请确认已经选择了正确的课程！', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(async () => {
              await classReview({
                id: this.classId,
                status: '已启用'
              })
              window.$msg('审核成功')
              this.$emit('getClassTeacherInfoById')
              this.close()
            })
            .catch(() => {
              return false
            })
        }
      }
      }
</script>

<style scoped>

</style>
