<template>
  <div>
    <el-row class="mb-20">
<!--      <el-button @click="editStu" size="small" type="primary" v-has="'class:editClassStu'" v-if="!$route.query.productId" :disabled="Boolean(classInfo.finished_date) && (classInfo.finished_date !== '0001-01-01')">编 辑</el-button>-->
      <el-button @click="divideClass" size="small" type="primary" v-has="'class:divideintoClass'" :disabled="Boolean(classInfo.finished_date) && (classInfo.finished_date !== '0001-01-01') && (classInfo.finished_date !== '1900-01-01')">分 班</el-button>
      <el-button @click="recordClass" size="small" type="primary" v-has="'class:recordClassStu'" :disabled="Boolean(classInfo.finished_date) && (classInfo.finished_date !== '0001-01-01') && (classInfo.finished_date !== '1900-01-01')">出入班记录</el-button>
    </el-row>
    <!-- classStudent：{{classStudent}} -->
    <el-table
      :data="dataList"
      border
      fit
      highlight-current-row
    >
      <el-table-column align="center" label="学号" prop="loginName"></el-table-column>
      <el-table-column align="center" label="姓名" prop="userName"></el-table-column>
      <el-table-column align="center" label="性别" prop="sex"></el-table-column>
      <el-table-column align="center" label="来源" prop="source"></el-table-column>
      <el-table-column align="center" label="入班日期">
        <template slot-scope="scope">
          <span>{{scope.row.createTime | dateFormat}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="上课出勤" width="230">
        <template slot-scope="scope" class="pointer">
          <div class="pointer" @click="attendanceFun(scope.row)">
            <el-progress
              style="width: 55%"
              class="inline-block mr-10"
              :show-text="false"
              :percentage="scope.row.lessonTotalNum !== 0 ? Math.round(scope.row.lessonAttendNum / scope.row.lessonTotalNum * 100) : 0"
            ></el-progress>
            ({{scope.row.lessonAttendNum}} / {{scope.row.lessonTotalNum}})
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="课程进度" width="230">
        <template slot-scope="scope">
          <div class="pointer" @click="courseFun(scope.row)">
            <el-progress
              style="width: 55%"
              class="inline-block mr-10"
              :show-text="false"
              :percentage="scope.row.taskTNum !== 0 ? Math.round(scope.row.taskCompleteNum / scope.row.taskTNum * 100) : 0"
            ></el-progress>
            ({{scope.row.taskCompleteNum}} / {{scope.row.taskTNum}})
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="350">
        <template slot-scope="scope">
          <el-button @click="studentCourse(scope.row)" plain size="small" v-has="'class:studentcourse'" type="primary" :disabled="Boolean(classInfo.finished_date) && (classInfo.finished_date !== '0001-01-01') && (classInfo.finished_date !== '1900-01-01')">学员课程</el-button>
          <el-button @click="changePassword(scope.row)" plain size="small" type="primary" :disabled="Boolean(classInfo.finished_date) && (classInfo.finished_date !== '0001-01-01') && (classInfo.finished_date !== '1900-01-01')">修改密码</el-button>
<!--          <el-button-->
<!--            @click="delStudent(scope.row)"-->
<!--            plain-->
<!--            size="small"-->
<!--            type="danger"-->
<!--            v-has="'class:delClassStu'"-->
<!--            v-if="!$route.query.productId"-->
<!--            :disabled="Boolean(classInfo.finished_date) && (classInfo.finished_date !== '0001-01-01')"-->
<!--          >删 除</el-button>-->
          <el-button
            @click="adjustClass(scope.row)"
            plain
            size="small"
            type="info"
            v-has="'class:changeClass'"
            v-if="$route.query.productId"
            :disabled="Boolean(classInfo.finished_date) && (classInfo.finished_date !== '0001-01-01') && (classInfo.finished_date !== '1900-01-01')"
          >调 班</el-button>
          <el-button
            @click="exitClass(scope.row)"
            plain
            size="small"
            type="danger"
            v-has="'class:leaveClassStu'"
            :disabled="Boolean(classInfo.finished_date) && (classInfo.finished_date !== '0001-01-01') && (classInfo.finished_date !== '1900-01-01')"
          >退 班</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 选择学员 -->
    <sele-student
      :show="dialogEditStu"
      @close="dialogEditStu=false"
      :seleStuData="seleStuData"
      @editStuData="editStuData"
    ></sele-student>

    <!-- 修改密码dialog -->
    <change-password :resetUse="resetUse" :show="dialogPassword" @close="dialogPassword=false"></change-password>
    <!-- 学员课程dialog -->
    <student-course :resetUse="studentCourseObj" :show="dialogStuCourse" @close="dialogStuCourse=false"></student-course>
    <!-- 出勤详情 -->
    <attendance-detail :show="attendanceDialog" @close="attendanceDialog=false" :class_id="class_id" :user_id="user_id"></attendance-detail>
    <!-- 课程进度详情 -->
    <course-progress :show="courseDialog" @close="courseDialog=false" :course_id="course_id" :class_id="class_id" :user_id="user_id"></course-progress>
    <!-- 分班 -->
    <divide-class :show="divideDialog" @close="divideDialog=false" :seleStuData="seleStuData" @editStuData="editStuData" :classInfo="classInfo"></divide-class>
    <!-- 调班 -->
    <adjust-class :show="adjustDialog" @close="adjustDialog=false" :user_id="user_id" @updateView="getClassStudentInfoById"></adjust-class>
    <!-- 操作备注 -->
    <handle-remark :show="remarkDialog" @close="remarkDialog=false" :remarkData="remarkData" @updateView="getClassStudentInfoById"></handle-remark>
    <!-- 出入班记录 -->
    <handle-record :show="recordDialog" @close="recordDialog=false"></handle-record>
  </div>
</template>

<script>
import { getClassStudentInfoById, addStudent, delStudent, leaveClass } from '@/api/senate/class'
import SeleStudent from '../sele-student'
import changePassword from './change-password'
import studentCourse from './student-course'
import attendanceDetail from './attendance-detail'
import courseProgress from './course-progress'
import divideClass from '@/components/senate/divide-class'
import adjustClass from '@/components/senate/adjust-class'
import handleRemark from './components/handle-remark'
import handleRecord from './components/handle-record'

export default {
  props: {
    classStudent: {
      default: () => {
        return []
      },
      type: Array
    },
    classInfo: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data () {
    return {
      dataList: [],
      dialogEditStu: false,
      seleStuData: [],
      dialogPassword: false,
      resetUse: {},
      studentCourseObj: null,
      dialogStuCourse: false,
      class_id: '',
      course_id: '',
      attendanceDialog: false,
      courseDialog: false,
      user_id: '',
      divideDialog: false,
      adjustDialog: false,
      remarkDialog: false,
      remarkData: {
        userName: '',
        className: ''
      },
      recordDialog: false
    }
  },
  components: { SeleStudent, changePassword, studentCourse, attendanceDetail, courseProgress, divideClass, adjustClass, handleRemark, handleRecord },
  created () {
    if (!this.classStudent.length) {
      this.getClassStudentInfoById()
    } else {
      this.dataList = this.classStudent
    }
    this.class_id = this.$route.query.classID
    this.course_id = this.$route.query.courseId
  },
  methods: {
    async getClassStudentInfoById () {
      const res = await getClassStudentInfoById({
        class_id: this.$route.query.classID
      })
      this.dataList = res.body
    },

    // 修改密码
    changePassword (row) {
      this.resetUse = row
      this.dialogPassword = true
    },
    studentCourse (row) {
      this.studentCourseObj = row
      this.studentCourseObj.class_id = this.class_id
      this.dialogStuCourse = true
    },
    // 删除学员
    delStudent (row) {
      this.$confirm('是否删除该学员？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await delStudent({
            classId: this.$route.query.classID,
            userIdSet: [row.user_id]
          })
          window.$msg('删除成功')
          this.getClassStudentInfoById()
        })
        .catch(() => {
          return false
        })
    },

    // 编辑学员
    editStu () {
      // this.$nextTick(() => {
      let stuData = []
      if (this.dataList.length !== 0) {
        this.dataList.map((item, index) => {
          stuData.push(
            Object.assign({}, item, {
              userId: item.user_id,
              name: item.userName
            })
          )
        })
        this.seleStuData = stuData
      }
      this.dialogEditStu = true
    },

    // 编辑学员-save
    async editStuData (data) {
      let userIdSet = []
      data.map((item, index) => {
        userIdSet.push(item.userId)
      })
      await addStudent({
        classId: this.$route.query.classID,
        userIdSet: userIdSet
      })
      window.$msg('更新班级成员成功')
      this.dialogEditStu = false
      this.getClassStudentInfoById()
      // this.getStudentMessage()
      // this.getClassMessage()
    },

    // 出勤详情
    attendanceFun (row) {
      if (row.lessonTotalNum === 0) {
        window.$msg('暂无出勤记录', 2)
      } else {
        this.user_id = row.user_id
        this.attendanceDialog = true
      }
    },

    // 课程详情
    courseFun (row) {
      if (row.taskTNum === 0) {
        window.$msg('暂无课程记录', 2)
      } else {
        this.user_id = row.user_id
        this.courseDialog = true
      }
    },

    adjustClass (row) {
      this.user_id = row.user_id
      this.adjustDialog = true
    },

    // 分班
    divideClass () {
      let stuData = []
      if (this.dataList.length !== 0) {
        this.dataList.map((item, index) => {
          stuData.push(
            Object.assign({}, item, {
              userId: item.user_id,
              name: item.userName
            })
          )
        })
        this.seleStuData = stuData
      }
      this.divideDialog = true
    },

    // 退班
    exitClass (row) {
      this.$confirm('该学员是否要退出当前班级', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          this.remarkData = row
          this.remarkDialog = true
          // await leaveClass({
          //   classId: this.$route.query.classID,
          //   userId: row.user_id
          // })
          // window.$msg('退班成功')
          // this.getClassStudentInfoById()
        })
    },

    // 出入班记录
    recordClass () {
      this.recordDialog = true
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
