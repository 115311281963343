import $axios from '@/utils/request'

// 同步用户
export function getXgjUsers (data) {
  const url = '/userXgj/getXgjUsers'
  return $axios.fPost(url, data)
}

// 批量同步排课及其成员信息
export function getXgjLessonBat (data) {
  const url = '/lessonXgj/getXgjLessonBat'
  return $axios.fPost(url, data)
}

// 批量更新班级及其相关信息
export function getXgjClasses (data) {
  const url = '/classXgj/getXgjClasses'
  return $axios.fPost(url, data)
}

// 批量同步试听学员
export function getXgjTryStudent (data) {
  const url = '/userXgj/getXgjTryStudent'
  return $axios.fPost(url, data)
}

// 更新班级
export function updateClass (data) {
  const url = '/classXgj/update'
  return $axios.fPost(url, data)
}

// 更新班级成员
export function updateClassMember (data) {
  const url = '/classXgj/refreshMember'
  return $axios.fPost(url, data)
}

// 更新班级排课列表
export function updateClassLesson (data) {
  const url = '/lessonXgj/update'
  return $axios.fPost(url, data)
}

// 更新排课学员
export function updateLessonMember (data) {
  const url = '/lessonXgj/refreshMemberByClass'
  return $axios.fGet(url, data)
}
