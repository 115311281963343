<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="5vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>更新授课课程</span>
    </div>
      <div >
        授课课程：
        <span>
           <el-cascader
             style="width: 40%"
             clearable
             v-model="courseId"
             :options="tableArr"
             :props="{ checkStrictly: true, value: 'id', label: 'title' }"
           ></el-cascader>
        </span>
      </div>
    <el-button style="margin-top: 15px;" type="primary" @click="editClassCourse" size="small">更新课程</el-button>
  </el-dialog>
</template>

<script>
  import { editClassCourse } from '@/api/senate/class'
  import getCourseType from '@/mixins/getCouresTypeLess'
    export default {
      name: "index",
      components: { },
      mixins: [getCourseType],
      props: {
        classInfo: {
          default: null,
          type: Object,
        },
        show: {
          default: false,
          type: Boolean,
        },
        classId: {
          default:'',
          type:String
        }
      },
      data() {
        return {
          courseId:[]
        }
      },
      methods: {
        openDialog() {
          this.courseId = []
        },
        async editClassCourse() {
          if(this.courseId.length === 0) {
            window.$msg('请先选择授课课程',2)
            return  false
          }
          const res = await editClassCourse({
            id:this.classId,
            course_id:this.courseId [this.courseId.length - 1]
          })
          if(res && res.state === 'success') {
            window.$msg('更新授课课程成功')
            this.$emit('updateView')
            this.close()
          }
        },
        close() {
          this.$emit('close')
        },
      }
      }
</script>

<style scoped>

</style>
