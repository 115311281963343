import axios from 'axios'
import store from '../store'
import Vue from 'vue'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { showLoading, hideLoading } from '@/utils/loading'

const $axios = axios.create({
  // 基础url，会在请求url中自动添加前置链接
  baseURL: (process.env.NODE_ENV === 'development' && window.location.href.indexOf('localhost') !== -1) ? '/video' : process.env.VUE_APP_VIDEO_API_ADDRESS,
  // 设置超时时间
  withCredentials:true,
  // timeout: 80000
})
axios.defaults.withCredentials = true
Vue.prototype.$http = axios // 并发请求
// 在全局请求和响应拦截器中添加请求状态

// 请求拦截器
$axios.interceptors.request.use(
  config => {
    NProgress.start()
    showLoading()
    const token = store.getters.token
    if (token) {
      config.headers.Authorization = token // 请求头部添加token
    }
    return config
  },
  error => {
    // 处理请求错误
    NProgress.done()
    hideLoading()
    console.log('debug', error)
    return Promise.reject(error)
  }
)
// 响应拦截器
$axios.interceptors.response.use(
  response => {
    NProgress.done()
    hideLoading()
    // const code = response.status // 获取响应状态码
    const res = response.data // 获取响应数据
    if (res.code === 2) {
        store.dispatch('user/tokenLose').then(() => {
          // location.reload()
        })
    }else {
      return Promise.resolve(res)
    }
  },
  error => {
    NProgress.done()
    hideLoading()
    console.log(error)
    if (error.response) {
      switch (error.response.status) {
        // case 401:
        //   // 返回401 清除token信息并跳转到登陆页面
        //   store.commit('DEL_TOKEN')
        //   router.replace({
        //     path: '/login',
        //     query: {
        //       redirect: router.currentRoute.fullPath
        //     }
        //   })
        //   break
        case 404:
          window.$msg('网络请求不存在', 1)
          break
        default:
          window.$msg(error, 1)
      }
    } else {
      // 请求超时或者网络有问题
      if (error.message.includes('timeout')) {
        window.$msg('请求超时！请检查网络是否正常', 1)
      } else {
        window.$msg('请求失败，请检查网络是否已连接', 1)
      }
    }
    return Promise.reject(error)
  }
)

// get，post请求方法
export default {
  fVideoPost (url, data) {
    return $axios({
      method: 'post',
      url,
      data: data,
      withCredentials:true,
      baseURL: (process.env.NODE_ENV === 'development' && window.location.href.indexOf('localhost') !== -1) ? '/video' : process.env.VUE_APP_VIDEO_API_ADDRESS
    })
  },
  fVideoGet (url, data) {
    return $axios({
      method: 'get',
      url,
      params: data,
      withCredentials:true,
      baseURL: (process.env.NODE_ENV === 'development' && window.location.href.indexOf('localhost') !== -1) ? '/video' : process.env.VUE_APP_VIDEO_API_ADDRESS
    })
  }
}
