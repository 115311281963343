<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>出入班记录</span>
    </div>
    <el-row>
      <el-table
        :data="dataList"
        border
        fit
        highlight-current-row
        class="tb-list"
      >
        <el-table-column label="学员名" align="center" prop="userName"></el-table-column>
        <el-table-column label="出入班" align="center" prop="type"></el-table-column>
        <el-table-column label="时间" align="center" prop="createTime"></el-table-column>
        <el-table-column label="备注" align="center" prop="remark"></el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="this.pagination.total"
      ></el-pagination>
    </el-row>
  </el-dialog>
</template>

<script>
import { getListMemberLog } from '@/api/senate/class'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      }
    }
  },
  methods: {
    openDialog () {
      this.getListMemberLog()
    },

    async getListMemberLog () {
      const res = await getListMemberLog({
        pageNum:this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        classId: this.$route.query.classID
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },

    // 关闭
    close () {
      this.$emit('close')
    },

    // 分页
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getListMemberLog()
    },
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getListMemberLog()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../style/dialog.scss";
</style>
