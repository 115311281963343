<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>学员调班</span>
    </div>
    <el-row>
      <el-select v-model="schoolValue" placeholder="请选择校区" class="tb-smselect">
        <el-option v-for="item in schoolArray" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <el-input
        v-model.trim="classNameValue"
        placeholder="请输入班级名称"
        @keyup.enter.native="search"
        class="tb-sm-input"
      ></el-input>
      <el-button type="primary" size="medium" @click="search" class="tb-button">搜索</el-button>
      <el-button type="primary" size="medium" @click="reset" class="tb-button">重置</el-button>

      <el-table
      :data="dataList"
      border
      fit
      highlight-current-row
      class="tb-list"
    >
      <el-table-column label="校区" align="center" prop="campusName"></el-table-column>
      <el-table-column label="班级" align="center" prop="name"></el-table-column>
      <el-table-column label="操作" align="center" width="180">
        <template slot-scope="scope">
          <el-button @click="adjustTo(scope.row)" plain size="small" type="primary">调 入</el-button>
        </template>
      </el-table-column>
      </el-table>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>

      <!-- adjust-date -->
      <adjust-date
        :show="adjustDialog"
        @close="adjustDialog=false"
        :user_id="user_id"
        :classIdTo="classIdTo"
        @updateView="updateView"
      ></adjust-date>
    </el-row>
  </el-dialog>
</template>

<script>
import { getCampusAll, getClassAll } from '@/api/senate/class'
import adjustDate from '@/components/senate/adjust-date'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    user_id: {
      default: '',
      type: String
    }
  },
  components: { adjustDate },
  data () {
    return {
      schoolValue: '',
      schoolArray: [],
      classNameValue: '',
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      adjustDialog: false,
      classIdTo: ''
    }
  },
  methods: {
    openDialog () {
      this.getCampusAll()
      this.getClassAll()
    },

    // 分页
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      // this.getListListenStu()
    },
    handleSizeChange (val) {
      this.pagination.pageSize = val
      // this.getListListenStu()
    },
    search () {
      this.pagination.currentPage = 1
      this.getClassAll()
    },
    reset () {
      this.schoolValue = ''
      this.classNameValue = ''
    },

    // 获取全部校区
    async getCampusAll () {
      const res = await getCampusAll()
      this.schoolArray = res.body
    },

    // 获取所属收费课程的列表
    async getClassAll () {
      const res = await getClassAll({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount: true,
        productId: this.$route.query.productId,
        campus_id: this.schoolValue,
        className: this.classNameValue,
        isFinish: false,
        exceptClassIds: [this.$route.query.classID]
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },

    // 调入
    adjustTo (row) {
      this.classIdTo = row.class_id
      this.adjustDialog = true
    },

    close () {
      this.$emit('close')
    },

    updateView () {
      this.close()
      this.$emit('updateView')
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../../style/dialog.scss"
</style>
