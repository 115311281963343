<template>
  <div class="components-container">
    <div class="editor-container">
      <dnd-list
        :courseList="courseList"
        :lessonsList="lessonsList"
        :classInfo="classInfo"
        :scrollTopTable="scrollTopTable"
        courseListTitle="排课列表"
        lessonsListTitle="课节列表"
        @updateView="getLessonInfoByClassId()"
      />
    </div>
  </div>
</template>

<script>
import DndList from '@/components/senate/detail-pane/components/lesson-list'
import { getLessonInfoByClassId, getCourseUnitListOnly } from '@/api/senate/class'
// import { getCourseUnitList } from '@/api/research/curriculum'

export default {
  components: { DndList },
  props: {
    classInfo: {
      default: () => {
        return {}
      },
      type: Object
    },
    scrollTopTable: {
      default: 0,
      type: Number
    }
  },
  data() {
    return {
      courseList: [],
      lessonsList: []
    }
  },
  created() {
    this.getLessonInfoByClassId() // 获取班级排课信息 -> 排课课节
    this.getCourseUnitListOnly()  // 获取课节列表
  },
  methods: {
    async getLessonInfoByClassId () {
      const res = await getLessonInfoByClassId({
        class_id: this.$route.query.classID,
        needCount: true,
        pageNum: 1,
        pageSize: 1000
      })
      this.courseList = res.body.list
      // console.log('getLessonInfoByClassId', res)
    },

    async getCourseUnitListOnly () {
      const res = await getCourseUnitListOnly({
        classId: this.$route.query.classID,
        needCount: true,
        pageNum: 1,
        pageSize: 1000,
        status: '已启用'
      })
      this.lessonsList = res.body.list
      // console.log('getCourseUnitListOnly', res)
    }
  }
}
</script>
