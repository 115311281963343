<template>
  <div class="table" @scroll.passive="getScroll($event)">
    <span class="back-tit">班级详情</span>
    <div class="row-bg">
      <span v-text="classInfo.className" style="margin-right: 25px"></span>
      <el-tooltip
        class="tb-tooltip-button"
        content="本班数据与校管家不一致时，如班级学员缺失、排课记录缺失，点击同步本班数据"
        effect="dark"
        placement="top-start"
      >
        <el-button
          @click="syncClassData"
          size="medium"
          class="tb-button"
          type="primary"
          :disabled="Boolean(classInfo.finished_date) && (classInfo.finished_date !== '0001-01-01') && (classInfo.finished_date !== '1900-01-01')"
        >同步本班数据</el-button>
        <!-- v-has="'class:syncClassData'" -->
      </el-tooltip>
      <el-button
        @click="examineClass"
        size="medium"
        type="primary"
        v-has="'class:review'"
        v-if="classInfo.status == '已启用'"
        class="tb-button"
        disabled
      >{{classInfo.status}}</el-button>

      <el-button
        @click="examineClass"
        size="medium"
        type="danger"
        v-has="'class:review'"
        v-if="classInfo.status == '待审核'"
        :disabled="Boolean(classInfo.finished_date) && (classInfo.finished_date !== '0001-01-01') && (classInfo.finished_date !== '1900-01-01')"
        class="tb-button"
      >审核班级</el-button>
    </div>
<!--     classInfo：{{classInfo}}-->
    <el-tabs class="tb-tabs" v-model="activeName">
      <el-tab-pane v-for="item in tabMapOptions" :key="item.key" :label="item.label" :name="item.key">
        <!-- <keep-alive> -->
        <class-detail
          v-if="activeName==item.key"
          :type="item.key"
          :classInfo="classInfo"
          :classStudent="classStudent"
          :scrollTopTable="scrollTopTable"
          @getClassTeacherInfoById="getClassTeacherInfoById"
        ></class-detail>
        <!-- </keep-alive> -->
      </el-tab-pane>
    </el-tabs>
    <examine-class :show="examineClassFlag" :classInfo="classInfo" :classId="class_id" @getClassTeacherInfoById="getClassTeacherInfoById" @close="examineClassClose"></examine-class>
  </div>
</template>

<script>
import { getClassTeacherInfoById, getClassStudentInfoById, classReview, getLessonInfoByClassId } from '@/api/senate/class'
import { updateClass, updateClassMember, updateClassLesson, updateLessonMember } from '@/api/system/sync'
import ClassDetail from '@/components/senate/detail-pane'
import examineClass from '@/components/senate/examineClass'

export default {
  watch: {
    activeName (val) {
      this.$router.push({
        path: this.$route.path,
        query: {
          courseId: this.course_id,
          classID: this.class_id,
          productId: this.product_id,
          tab: val
        }
      })
    },
    classInfo (newVal) {
      // console.log('classInfo', newVal)
      this.$router.push({
        path: this.$route.path,
        query: {
          courseId: newVal.courseId,
          classID: this.class_id,
          productId: this.product_id,
          tab: this.activeName
        }
      })
    }
  },
  components: { ClassDetail,examineClass },
  inject: ['reload'],
  data () {
    return {
      class_id: '', // 班级id
      course_id: '', // 课程id
      product_id: '', // 收费课程id
      classInfo: {}, // 班级基本信息
      classStudent: [], // 班级学员列表
      activeName: 'Info',
      examineClassFlag:false, //审核班级
      tabMapOptions: [
        { label: '基本信息', key: 'Info' },
        { label: '班级学员', key: 'Student' },
        { label: '班级排课', key: 'Lesson' },
        { label: '课程目录', key: 'Catalogue' },
        { label: '学员动态', key: 'ClassStudentState' },
        { label: '课节关联', key: 'Relevancy' }
      ],
      scrollTopTable: 0
    }
  },
  created () {
    if (Boolean(this.$route.query.tab)) {
      this.activeName = this.$route.query.tab
    }
    // let perms = JSON.parse(localStorage.getItem('perms'))
    // perms.findIndex(item => {
    //   if (item === 'class:liveMan') {
    //     this.tabMapOptions.push({ label: '直播间管理', key: 'live' })
    //   }
    // })
    this.class_id = this.$route.query.classID
    this.course_id = this.$route.query.courseId
    this.product_id = this.$route.query.productId
    this.getClassTeacherInfoById()
  },

  beforeDestroy () {
    sessionStorage.setItem('isAsync', false)
  },
  methods: {
    examineClassClose() {
      this.examineClassFlag = false
      this.getClassTeacherInfoById()
    },
    back () {
      this.$router.go(-1)
    },

    getScroll (event) {
      // console.log(event.target.scrollTop)
      this.scrollTopTable = event.target.scrollTop
    },

    // 同步本班数据
    async syncClassData () {
      sessionStorage.setItem('isAsync', true)
      await updateClass({ classId: this.class_id }) // 更新班级
      await updateClassMember({ classId: this.class_id }) // 更新班级成员
      await updateClassLesson({ classId: this.class_id }) // 更新班级排课列表
      await updateLessonMember({ classId: this.class_id }) // 更新班级排课成员
    },

    // 获取班级信息
    async getClassTeacherInfoById () {
      const res = await getClassTeacherInfoById({
        class_id: this.class_id
      })
      this.classInfo = res.body
    },

    // 获取班级排课信息
    async getLessonInfoByClassId () {
      const res = await getLessonInfoByClassId({ class_id: this.class_id })
      this.classLessonList = res.body
    },

    // 获取班级学员
    async getClassStudentInfoById () {
      const res = await getClassStudentInfoById({
        class_id: this.$route.query.classID
      })
      this.classStudent = res.body
    },

    // 审核班级
    examineClass () {
      this.examineClassFlag = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";
</style>
